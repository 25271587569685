import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { FaArrowRight } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';
import './Project.css'

const Categories = () => {

    const AppUrl = appUrl();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const [categoriesList, setCategoriesList] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [showNextButton, setShowNextButton] = useState(false);

    useEffect(() => {
        showCategories()
    }, [])

    const showCategories = async () => {
        try {
            const agentToken = localStorage.getItem('token');
            const response = await fetch(`${AppUrl}/agent/categories?project_type=${selectedItem}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${agentToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            console.log(data, "datatata");
            if (data.status === 200) {
                setCategoriesList(data.services);
            } else {
                NotificationManager.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const CategoryBack = () => {
        navigate(`/agent/${selectedItem}/saved-addresses`)
        setSelectedCategories('')
        setShowNextButton(false)
    }


    const handleCheckboxChange = (categoryId) => {
        let newSelectedCategories;
        if (selectedCategories.includes(categoryId)) {
            newSelectedCategories = selectedCategories.filter(id => id !== categoryId);
        } else {
            newSelectedCategories = [...selectedCategories, categoryId];
        }
        setSelectedCategories(newSelectedCategories);
        setShowNextButton(newSelectedCategories.length > 0);
    };

    return (
        <>
            <NotificationContainer />
            <div className='categories'>
                <div className='enter-head mb-3'>
                    <IoMdArrowBack onClick={CategoryBack} />
                    <h5>{selectedItem}</h5>
                    <p></p>
                </div>
                <ul>
                    {categoriesList.map((category, id) => (
                        <li key={id}>
                            <div>
                                <img src={category.icon} alt={category.name} />
                                {category.name}
                            </div>
                            <div className='category-select'>
                                <input
                                    type='checkbox'
                                    onChange={() => handleCheckboxChange(category._id)}
                                    checked={selectedCategories.includes(category._id)}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
                {showNextButton && (<div className='check_email_btn'>
                    <Button className='emailbtn' onClick={() => {
                        const categoriesJSON = JSON.stringify(selectedCategories);
                        navigate(`/agent/${selectedItem}/project`, { state: { selectedCategories: categoriesJSON },setSelectedCategories});
                    }}>
                        <p>Next</p>
                        <FaArrowRight />
                    </Button>
                </div>)}
            </div>
        </>
    )
}

export default Categories
