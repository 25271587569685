import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import '../Log.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import appUrl from '../../../src/appUrl';
import { useAuth } from '../../Store/auth';
import { requestForToken } from '../../firebase';


const AgentLogin = () => {
    const AppUrl = appUrl();
    const navigate = useNavigate();
    const { storeTokenInLS } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [login, setLogin] = useState({
        email_address: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        if (e && e.target) {
            const { name, value } = e.target;
            setLogin((prevData) => ({
                ...prevData,
                [name]: value || '',
            }));
        }
    };

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear()
    },[]);

    const handleShowPassword = () => {
        setShowPassword(true);
    };

    const handleHidePassword = () => {
        setShowPassword(false);
    };

    const handleAgentLog = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const deviceTokenPromise = requestForToken();
            const deviceToken = await deviceTokenPromise;
            console.log(deviceToken, "device")
            localStorage.setItem("deviceToken",deviceToken)
            const response = await fetch(`${AppUrl}/agent/login-with-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                // body: JSON.stringify(login)
                body: JSON.stringify({ ...login, device_token: deviceToken || "", device_type : "web" }),
            });
            const data = await response.json();
            if (response.ok && data.status === 200 && data.data.is_active) {
                console.log("Login successful");
                storeTokenInLS(data.token);
                setIsLoading(false);
                console.log("data", data);
            } else if (response.ok && data.status === 204 && !data.is_active) {
                localStorage.setItem("token",data.token)
                navigate('/agent/register/otp', { state: { email: login.email_address }, });
            } else if (data.status === 401 || data.status === 404 || !login.email_address || !login.password) {
                NotificationManager.error(data.message || 'Please fill all fields', 'Error');
            } else {
                NotificationManager.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlesignup = () => {
        navigate('/agent/register');
    };

    const handleForget = () => {
        navigate('/agent/forget-password');
    };


    return (
        <>
            <Row className='m-0 agent_login_form_wrap'>
                <Col className='login' lg={6} md={6} sm={12} xs={12}>
                    <div className="login_form_wrap pt-5">
                        <div className='login-head'>
                            <h2 className='mb-3'>Welcome Back</h2>
                            <img src='/images/welcome.png' alt='welcome'></img>
                        </div>
                        <p className='mb-4'>Resume your home’s upgrade journey</p>
                        <Form>
                            <Form.Group controlId="formBasicEmail" className="position-relative mb-3">
                                <i className="fa-solid fa-envelope login_icon_wrap"></i>
                                <Form.Control
                                    type="email"
                                    name="email_address"
                                    placeholder="Email"
                                    value={login.email_address}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className="position-relative mb-3">
                                <i className="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Password"
                                    value={login.password}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />
                                {showPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHidePassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap" onClick={handleShowPassword}></i>
                                )}

                            </Form.Group>

                            <p className='forget mb-4' onClick={handleForget}>Forgot password?</p>
                            <Button className='loginbtn mb-3' onClick={handleAgentLog} type='submit' disabled={isLoading}>Sign in</Button>{' '}
                        </Form>
                        <p className='account'>Don’t have an account? <span onClick={handlesignup}>Sign up</span></p>
                    </div>
                </Col>
                <Col className='logo-column' lg={6} md={6} sm={12} xs={12}>
                    <div className='column-center'>
                        <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>
                        <div><h6>Agent</h6></div>
                    </div>
                </Col>
            </Row>
            <NotificationContainer />
        </>
    )
}

export default AgentLogin;
