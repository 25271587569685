import React, { useState } from 'react'
import { Form, Button, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';
import { useNavigate,useLocation} from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";

const CreateAccount = ({ emailAddress}) => {

    const AppUrl = appUrl();
    const navigate= useNavigate();
    const location = useLocation(); 
    const selectedItem = location.pathname.split('/')[2];
    const [signModal, setSignModal] = useState(false);
    console.log(emailAddress)

    const [isLoading, setIsLoading] = useState(false);
    const [account, setAccount] = useState({
        email_address: localStorage.getItem("user-email") || '',
        first_name: '',
        last_name: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAccount({
            ...account,
            [name]: value
        });
    }

    const createNewAccount = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        try {
            const referralId = localStorage.getItem("referralId")
            const agentToken = localStorage.getItem("token")
            console.log(referralId)
            const response = await fetch(`${AppUrl}/agent/create-new-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${agentToken}`,
                },
                body: JSON.stringify(account, { referred_by: referralId })
            });
            const data = await response.json();
            console.log(data)
            if (data.status == 200) {
                // NotificationManager.success(data.message)
                const foundUserId = data.data.userId;
                localStorage.setItem("userId", foundUserId);
                console.log(foundUserId, "found")
                setSignModal(true);
                setIsLoading(false)
            } else if (data.status === 400) {
                NotificationManager.error(data.message);
                setIsLoading(false)
            }
            else {
                NotificationManager.error(data.message);
                setIsLoading(false)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div className={`new-account  ${isLoading ? 'blur-effect' : ''}`}>
                <div className='enter-head mb-3'>
                    <IoMdArrowBack  onClick={() => navigate(`/agent/${selectedItem}/email`)}  />
                </div>
                <p className='mb-4'>Email is not registered, please create an account</p>

                <h3 className='mb-3'>Create Account</h3>
                <h6 className='mb-4'>All fields are required</h6>

                <Form onSubmit={createNewAccount}>

                    <Form.Group className='mb-3' controlId="formBasicPassword" required>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type='email' name="email_address" value={account.email_address} disabled />
                    </Form.Group>

                    <div className='form-col mb-5'>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <Form.Group className='mb-3' controlId="formBasicPassword" required>
                                    <Form.Label>First name</Form.Label>
                                    <Form.Control type='text' name="first_name" value={account.first_name} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <Form.Group className='mb-3 ' controlId="formBasicPassword" required>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type='text' name="last_name" value={account.last_name} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className='addbutton'>
                        <Button className='add-btn' type="submit" onClick={createNewAccount} disabled={isLoading} >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className='address-spinner'
                                    />
                                </>
                            ) : (
                                <>
                                    Submit
                                </>
                            )}
                        </Button>
                    </div>
                </Form>
            </div>
            <Modal show={signModal} onHide={() => setSignModal(false)} className='invite-modal'
            backdrop="static">
                <Modal.Body>
                    <img src='/images/invite.png' alt='checked' className='mb-4' />
                    <p className='invite mb-4'>Invite has been <br />Sent Successfully!</p>
                    <Button className='invite-submit-btn' onClick={()=> navigate(`/agent/${selectedItem}/saved-addresses`)}>Okay</Button>
                </Modal.Body>
            </Modal>
            <NotificationContainer />
        </>
    )
}

export default CreateAccount
