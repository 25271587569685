import React, { useState, useEffect } from 'react'
import Button from "react-bootstrap/Button"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const Notifications = () => {
    const [notificationList, setNotificationList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        showNotification(page);
    }, [page]);

    const showNotification = async (page) => {
        try {
            const agentToken = localStorage.getItem('token');
            const response = await fetch(`https://bedrock-v2.netscapelabs.com/notification/v1/fetch-notifications?page=${page}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${agentToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            if (data.status === 200) {
                setNotificationList(data.notifications);
                setTotalPages(data.totalPages);
            } else {
                NotificationManager.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <>
            <NotificationContainer />
            <div className='renovation'>
                <div className='mb-5'>
                    {notificationList.length > 0 ? (
                        notificationList.map(list =>
                            <div key={list._id} className='display-address'>
                                <div className='notification-details mb-3'>
                                    {list.title}
                                </div>
                                <p className='mb-3'>
                                    {list.body}
                                </p>
                                <p className='notification-date'>
                                    {new Date(list.createdAt).toLocaleDateString()}
                                </p>
                            </div>
                        )
                    ) : (
                        <p>No notifications</p>
                    )}
                </div>
                <div className='pagination'>
                    {[...Array(totalPages).keys()].map(index => (
                        <Button
                            key={index}
                            variant='light'
                            onClick={() => handlePageChange(index + 1)}
                            disabled={page === index + 1}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Notifications
