import React from 'react';
import { Routes, Route,Navigate} from 'react-router-dom';
import CheckEmail from '../components/Agent/CheckEmail';
import CreateAccount from '../components/Agent/CreateAccount';
import SavedAddresses from '../components/Agent/SavedAddresses';
import Address from '../components/Agent/Address';
import InspectionProject from '../components/Agent/InspectionProject';
import ProjectTable from '../components/Agent/ProjectTable';
import UserTable from '../components/Agent/UserTable';

const Inspection = () => {

  const PrivateRouteWithLocalStorage = ({ element: Element, ...rest }) => {
    const userId = localStorage.getItem("userId");
    const userEmail = localStorage.getItem("user-email");
  
    return userId && userEmail ? <Element {...rest} /> : <Navigate to="/agent/inspection" />;
  };
  
  return (
  <div className='renovation'>
    <Routes>
        <Route path="/" element={<ProjectTable/>} />
        <Route path="user-table/:id" element={<PrivateRouteWithLocalStorage element={UserTable} />}/>
        <Route path="email"  element={<CheckEmail />} />
        <Route path="create-account" element={<PrivateRouteWithLocalStorage element={CreateAccount} />} />
        <Route path="address" element={<PrivateRouteWithLocalStorage element={Address} />} />
        <Route path="edit-address/:id" element={<PrivateRouteWithLocalStorage element={Address} />} /> 
        <Route path="saved-addresses" element={<PrivateRouteWithLocalStorage element={SavedAddresses} />} />
        <Route path="project" element={<PrivateRouteWithLocalStorage element={InspectionProject} />} />
    </Routes></div>
  );
};

export default Inspection;
