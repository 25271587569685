import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, Modal, Badge } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import appUrl from '../appUrl';
import "../components/Agent/Table.css"
import { IoInformationCircleOutline } from "react-icons/io5";

const Rewards = () => {
  const AppUrl = appUrl()
  const navigate = useNavigate();
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [points, setPoints] = useState()
  const [pointsModal, setPointsModal] = useState(false);
  const [congratsModal, setCongratsModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [rejectDescription, setRejectDescription] = useState(null)
  const [rejectModel, setRejectModel] = useState(false)

  // console.log(currentPage, "currentPage")
  // console.log(totalPages, "totalPages")
  // console.log(totalCount, "totalCount")
  // console.log(pageSize, "pageSize")

  // console.log(rewards, "reward")

  console.log(typeof points, "type");
  // console.log(typeof currentPage, "typeC");

  const handleSearchChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
  };

  const filteredRewards = rewards?.[0]?.data?.filter(reward => {
    return (
      reward.entityDetails.uniqueId?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      reward.entityDetails.userDetails?.[0]?.userInfo?.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      reward.entity?.toLowerCase().includes(searchText.toLowerCase()) ||
      reward.createdAt?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      reward.available_on?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      reward.amount?.toString().toLowerCase().includes(searchText.toLowerCase())
    );
  }) ?? [];

  const handlePageChange = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
  };

  useEffect(() => {
    localStorage.removeItem("user-email");
    localStorage.removeItem("userId")
    listrewards();
  }, []);


  const listrewards = async () => {
    setLoading(true);
    try {
      const agentToken = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/agent/rewards/points`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${agentToken}`,
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json();
      console.log(data, "datatata");

      if (data.status === 200) {
        setRewards(data.rewards);
        setPageSize(data.perPage);
        setTotalPages(data.totalPages);
        setTotalCount(data.totalCount);
        setLoading(false);
      } else {
        NotificationManager.error('Failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const nameFormatter = (cell, row) => {
    if (row.entityDetails?.userDetails?.[0]?.userInfo?.first_name) {
      return row.entityDetails.userDetails[0].userInfo.first_name;
    }
    if (row.entityDetails?.userInfo?.first_name) {
      return row.entityDetails.userInfo.first_name;
    }
    if (row.entityDetails?.userDetails?.[0].first_name) {
      return row.entityDetails?.userDetails?.[0]?.first_name
    }
    return '-'
  };

  const pointsFormatter = (cell, row) => {
    let color = 'black';
    let points = row.points

    if (row.entity === 'project' || row.entity === 'user') {
      color = '#00C2FF';
      points = `${points} points`
    }
    else if (row.entity === 'withdrawal' && row.type === 'debit' && row.entityDetails.status === 'pending') {<h2 className='mb-2'></h2>
      color = 'orange';
      points = `-${points} points`
    }
    else if (row.entity === 'withdrawal' && row.type === 'debit' && row.entityDetails.status === 'accepted') {
      color = 'red';
      points = `-${points} points`
      return (
        <>
          <span style={{ color: color, fontWeight: 500, marginRight: "5px" }}>{points}</span>
          <IoInformationCircleOutline
            onClick={() => (handleRejected(row._id), console.log(row._id, "idid"))}
            style={{ color: "grey" }}
          />
        </>
      )
    }
    else if (row.entity === 'withdrawal' && row.type === 'debit' && row.entityDetails.status === 'rejected') {
      color = 'green';
      points = `+${points} points`
      return (
        <>
          <span style={{ color: color, fontWeight: 500, marginRight: "5px" }}>{points}</span>
          <IoInformationCircleOutline
            onClick={() => (handleRejected(row._id), console.log(row._id, "idid"))}
            style={{ color: "grey" }}
          />
        </>
      )
    }
    else if (row.entity === 'withdrawal' && row.type === 'credit' && row.entityDetails.status === 'rejected') {
      color = 'grey';
      points = `${points} points`
      return (
        <>
          <span style={{ color: color, fontWeight: 500, marginRight: "5px" }}>{points}</span>
          {/* <IoInformationCircleOutline
            onClick={() => (handleRejected(row._id), console.log(row._id, "idid"))}
            style={{ color: "grey" }}
          /> */}
        </>
      )
    }

    return <span style={{ color, fontWeight: 600 }}>{points}</span>;
  };

  const handleRejected = (id) => {
    const description = rewards?.[0]?.data.find(reward => reward._id === id);
    if (description) {
      console.log(description, "descyyyyy");
      setRejectDescription(description);
    }
    setRejectModel(true)
  };

  const PointsStatusFormatter = (cell, row) => {
    let statusName = row.entityDetails.status;

    if (row.entityDetails.status === 'accepted' && row.entity === 'withdrawal' && row.type === 'debit') {
      statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
      return (
        <Badge bg="success">
          {statusName}
        </Badge>
      );
    } else if (row.entityDetails.status === 'pending' && row.entity === 'withdrawal' && row.type === 'debit') {
      statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
      return (
        <Badge bg="warning" text="dark">
          {statusName}
        </Badge>
      );
    } else if (row.entityDetails.status === 'rejected' && row.entity === 'withdrawal' && row.type === 'debit') {
      statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
      return (
        <Badge bg="danger">
          {statusName}
        </Badge>
      );
    }
    // In case none of the conditions match, return null or an empty string
    return new Date(cell).toLocaleDateString();
  };

  const columns = [
    {
      // dataField: 'entityDetails.uniqueId',
      // text: 'Id',
      formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
      dataField: 'index',
      text: 'S No.',
      headerClass: 'serial-number-header ',
      classes: 'serial-number-column',
      // filter: textFilter({ placeholder: '' }),
    },
    {
      dataField: 'entityDetails.userDetails[0].userInfo.first_name',
      text: 'Name',
      filter: textFilter({ placeholder: '' }),
      formatter: nameFormatter
    },
    {
      dataField: 'entity',
      text: 'Entity',
      filter: textFilter({ placeholder: '' }),
    },
    // { dataField: 'comment', text: 'Name' },
    {
      dataField: 'createdAt',
      text: 'Created At',
      formatter: (cell) => new Date(cell).toLocaleDateString(), filter: textFilter({ placeholder: '' }),
    },
    {
      dataField: 'available_on',
      text: 'Available On',
      // formatter: (cell) => new Date(cell).toLocaleDateString(),
      formatter: PointsStatusFormatter,
      filter: textFilter({ placeholder: '' }),
    },
    {
      dataField: 'points',
      text: 'Points',
      filter: textFilter({ placeholder: '' }),
      formatter: pointsFormatter
    },
  ];

  const sendPoints = async (e) => {
    e.preventDefault()
    setButtonLoading(true)
    if (!points) {
      NotificationManager.error('Enter points');
      setButtonLoading(false);
      return;
    }
    if (parseInt(points) === 0) {
      NotificationManager.error('Points cannot be 0');
      setButtonLoading(false);
      return;
    }
    if (parseInt(points) < 100) {
      NotificationManager.error('Points cannot be less than 100');
      setButtonLoading(false);
      return;
    }
    try {
      const agentToken = localStorage.getItem("token");
      const response = await fetch(`${AppUrl}/agent/rewards/radeem`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${agentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: points, type: "point" })
      });
      const data = await response.json();
      if (data.status === 201) {
        setPoints('')
        setPointsModal(false)
        setCongratsModal(true)
        setButtonLoading(false)
      }
      else {
        NotificationManager.error(data.message);
        setPoints('')
        setButtonLoading(false)
        setPointsModal(false)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePointsChange = (e) => {
    const value = e.target.value;
    setPoints(value === '' ? '' : Number(value)); // Handle empty string case
  };

  // const handlePointsChange = (e) => {
  //   const value = e.target.value;
  //   // Convert value to number only if it's not empty
  //   const num = value ? parseInt(value, 10) : '';
  //   setPoints(num);
  // };

  // const sortedRewards = rewards?.[0]?.data.slice().reverse();

  return (
    <>
      <NotificationContainer />
      <div className='renovation'>
        <div className="reward-points mb-4">
          <div>
            <h2 className='mb-2'>{rewards.length > 0 ? (rewards[0].totalAvailableAmount - rewards[0].withdrawalPoints).toFixed(2) : "-"}</h2>
            <p>Available Points</p>
          </div>
          <div>
            <h2 className='mb-2'>{rewards.length > 0 ? (rewards[0].withdrawalPoints.toFixed(2)) : "-"}</h2>
            <p>Withdrawn Points</p>
          </div>
          <div className='redeem-button' onClick={() => setPointsModal(true)}>
            <p>Redeem Points</p>
          </div>
        </div>

        <div className="show-entries">
          <p></p>
          {/* <div className='entries-dropdown'>
          <p className="custom-total">Show entries</p>
          <select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
            {[10, 20, 50, rewards.length].map((size, index) => (
              <option key={index} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div> */}

          <div>
            <Form.Group controlId="searchInput">
              <Form.Control
                type="text"
                placeholder="Search anything here"
                value={searchText}
                onChange={handleSearchChange}
              />
            </Form.Group>
          </div>
        </div>


        {loading ? (
          <div className='loading-spinner' >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className='mb-5'>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={filteredRewards}
              columns={columns}
              filter={filterFactory({ placeholder: '' })}
              pagination={paginationFactory({
                page: currentPage,
                sizePerPage: pageSize,
                totalSize: totalCount,
                onPageChange: handlePageChange,
                sizePerPageRenderer: () => null, // Remove the size per page dropdown
                showTotal: true, // Enable showing total entries
                paginationTotalRenderer: (from, to, total) => (
                  <div className="pagination-and-entries">
                    <p className="entries">
                      Showing {from} to {to} of {total} entries
                    </p>
                  </div>
                ),
              })}
            />
            {/* <div className="pagination-div">
              <p className='entries'>
                Showing {Math.min(pageSize * (currentPage - 1) + 1, totalCount)} to {' '}
                {Math.min(pageSize * currentPage, totalCount)}
                {Math.min(pageSize * currentPage, totalCount)}  of {totalCount} entries
              </p>
              <div className="pagination">
                {currentPage !== 1 && (
                  <p
                    onClick={() => handlePageChange(currentPage - 1, pageSize)}
                    className="pagination-next-prev"
                  >
                    Previous
                  </p>
                )}

                {Array.from({ length: totalPages }, (_, index) => (
                  <Button
                    key={index}
                    onClick={() => handlePageChange(index + 1, pageSize)}
                    className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                  >
                    {index + 1}
                  </Button>
                ))}
                {currentPage !== totalPages && (
                  <p
                    onClick={() => handlePageChange(currentPage + 1, pageSize)}
                    className="pagination-next-prev"
                  >
                    Next
                  </p>
                )}
              </div>
            </div> */}
          </div>
        )}
      </div>

      <Modal
        show={pointsModal}
        onHide={() => setPointsModal(false)}
        backdrop="static"
        className='redeem-model'
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body id='redeem-model'>
          <p className='mb-2'>Enter points to be redeem</p>
          <Form.Group controlId="formBasicPassword"
            className="redeem position-relative d-flex justify-content-center mb-4">
            <Form.Control
              type="number"
              name="points"
              placeholder=""
              value={points}
              onChange={handlePointsChange}
              onKeyDown={(e) => {
                if (!/^\d$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Tab'].includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Group>
          <Button className='redeem-btn mb-5' onClick={sendPoints} disabled={buttonLoading}>
            {buttonLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className='delete-spinner'
              />
            ) : (
              <h6>Redeem points</h6>
            )}</Button>
        </Modal.Body>
      </Modal>

      <Modal show={congratsModal} onHide={() => setCongratsModal(false)} className='celebrate-modal p-0'
        backdrop="static">
        <Modal.Body>
          {/* <img id="congrats-bg" src='/images/celebration.png' alt="celebration"/> */}
          <div className='congrats'>
            <img src='/images/celebration.png' alt='checked' id="celebrate" className='mb-4' />
            {/* <p className='invite'>Withdraw amount request has been sent successfully!</p> */}
          </div>
          <p className=' mb-4'>Withdraw points request has been sent successfully</p>
          <Button className='celebrate-submit-btn mb-3' onClick={() => (setCongratsModal(false), window.location.reload())}>Okay</Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={rejectModel}
        onHide={() => setRejectModel(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body id='redeem-model'>
          <Form>

            <Form.Group controlId="name" className='mb-3'>
              <Form.Label>Id</Form.Label>
              <Form.Control
                value={rejectDescription?.entityDetails?.uniqueId || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="name" className='mb-3'>
              <Form.Label>Points</Form.Label>
              <Form.Control
                value={rejectDescription?.entityDetails?.amount || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="name" className='mb-3'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                value={rejectDescription?.entityDetails?.description || ""}
                style={{ height:" 100%" }}
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default Rewards;

