import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { Card } from 'react-bootstrap';
import appUrl from '../../appUrl';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
  const AppUrl = appUrl();

  const [cardsData, setCardsData] = useState([
    {
      id: 1,
      text: 'Rewards Points',
      bgColor: '#FFDFE2',
      img: '/images/reward.png',
      count: "-"
    },
    {
      id: 2,
      text: 'Cashback Available',
      bgColor: '#E0EFF6',
      img: '/images/refund.png',
      count: "-"
    },
    {
      id: 3,
      text: 'Bedrock Ranking',
      bgColor: '#FFE1C1',
      img: '/images/trophy.png',
      count: "-"
    },
  ]);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    listData();
  }, []);

  const listData = async () => {
    try {
      const Token = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/agent/rewards/home-dashboard`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log(data, "datatata");

      if (data.status === 200) {
        // Update card counts dynamically based on the count retrieved from API
        setCardsData(prevCardsData => prevCardsData.map(card => {
          if (card.text === 'Rewards Points') {
            return { ...card, count: data.rewards.totalPoints };
          } else if (card.text === 'Cashback Available') {
            return { ...card, count: data.rewards.totalAmount };
          } else if (card.text === 'Bedrock Ranking') {
            return { ...card, count: data.rewards.myrank[0].rank };
          } else {
            return card;
          }
        }));

        // Prepare chart data
        const months = [
          'Jan', 'Feb', 'March', 'April', 'May', 'June',
          'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
        ];

        const chartData = months.map((month, index) => ({
          name: month,
          points: data.rewards.monthlyPoints[index],
          amount: data.rewards.monthlyAmount[index]
        }));

        setChartData(chartData);
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className='renovation'>
        <div className='inspection'>
          <div className='inspection-row'>
            <h6 id="dash" className='mb-3'>Accrued Rewards</h6>
            <div className="d-flex cards">
              {cardsData.map((card) => (
                <Card
                  key={card.id}
                  style={{ width: '18rem', margin: '6px', backgroundColor: card.bgColor }}
                  className='inspection-card'
                >
                  <Card.Body>
                    <Card.Img src={card.img} className='card-img' />
                    <Card.Title>{card.count}</Card.Title>
                    <Card.Text>{card.text}</Card.Text>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        </div>

       <h6 id="dash" className='mb-3'>Earnings overview</h6>
      <div className='graph-card'>
        <ResponsiveContainer width="100%" >
          <BarChart data={chartData}>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            {/* <YAxis/> */}
            <Tooltip />
            <Legend />
            <Bar dataKey="points" fill="#FE6F00" />
            <Bar dataKey="amount" fill="#f25939" />
          </BarChart>
        </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
