import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, Modal, Badge } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { FaPlus } from 'react-icons/fa';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useNavigate, useLocation } from 'react-router-dom';
import appUrl from '../../appUrl';
import { useAuth } from '../../Store/auth';
import './Table.css'

const ProjectTable = () => {
  const location = useLocation();
  const selectedItem = location.pathname.split('/')[2];
  const AppUrl = appUrl()
  const navigate = useNavigate();
  const { savedAddresses, setSavedAddresses } = useAuth();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [inviteModal, setInviteModal] = useState(false);

  console.log(projects, "pro")

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const filteredProjects = projects.filter(project => {
    const formattedStatus = project.status === 'active' ? 'pending' : project.status; 
    return (
      project.userInfo.first_name?.toLowerCase().includes(searchText.toLowerCase()) ||
      project.userInfo.email_address?.toLowerCase().includes(searchText.toLowerCase())
      // formattedStatus.toLowerCase().includes(searchText.toLowerCase()) ||
    );
  });

  const handleCreate = () => {
    navigate(`/agent/${selectedItem}/email`);
    localStorage.removeItem("user-email")
  }

  const handlePageChange = (page, sizePerPage) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    localStorage.removeItem("user-email");
    localStorage.removeItem("userId")
    if (savedAddresses.length > 0) {
      setSavedAddresses([])
    }
    listProjects();
  }, [currentPage]);

  const listProjects = async () => {
    setLoading(true);
    try {
      const agentToken = localStorage.getItem('token');
      let response;

      if (selectedItem === "repair" || selectedItem === "renovation") {
        response = await fetch(`${AppUrl}/agent/all-my-clients?project_type=${selectedItem}&page=${currentPage}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${agentToken}`,
            'Content-Type': 'application/json',
          }
        });
      } else if (selectedItem === "inspection") {
        response = await fetch(`${AppUrl}/agent/inspection/getmyall-clients?page=${currentPage}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${agentToken}`,
            'Content-Type': 'application/json',
          }
        });
      }
      const data = await response.json();
      console.log(data, "datatata");

      if (data.status === 200) {
        setProjects(data.data);
        setPageSize(data.perPage);
        setTotalPages(data.totalPages);
        setTotalCount(data.totalCount);
        setLoading(false);
      } else {
        NotificationManager.error('Failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const AccountStatusFormatter = (cell, row) => {
    let statusName = row.status;

    if (row.status === 'active') {
      statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
      return (
        <Badge bg="success">
          {statusName}
        </Badge>
      );
    } else if (row.status === 'draft') {
      statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
      return (
        <Badge bg="warning" text="dark">
          {statusName}
        </Badge>
      );
    } else if (row.status === 'cancelled') {
      statusName = statusName.charAt(0).toUpperCase() + statusName.slice(1);
      return (
        <Badge bg="danger">
          {statusName}
        </Badge>
      );
    }
    // In case none of the conditions match, return null or an empty string
    return null;
  };

  const AccountActionFormatter = (cell, row, id) => {
    let button;
    const userId = row._id;
    const email = row?.userInfo.email_address;
    const count = row?.projectCount;
  
    switch (row.status) {
      case 'draft':
        if (count === 0) {
          button = (
            <div className='d-flex'>
              <Button
                type="button"
                className="resend-button"
                onClick={() => {
                  localStorage.setItem("user", userId);
                  setInviteModal(true)
                }}
              >
                Resend Invite
              </Button>
              <Button
                type="button"
                className="view-button"
                onClick={() => {
                  localStorage.setItem("user-email", email);
                  localStorage.setItem("userId", userId)
                  navigate(`/agent/${selectedItem}/saved-addresses`);
                }}
              >
                Create Project
              </Button>
            </div>
          );
        } else {
          button = (
            <div className='d-flex'>
              <Button
                type="button"
                className="resend-button"
                onClick={() => {
                  localStorage.setItem("user", userId);
                  setInviteModal(true)
                }}
              >
                Resend Invite
              </Button>
              <Button
                className='view-button'
                onClick={() => {
                  localStorage.setItem("user-email", email);
                  localStorage.setItem("userId", userId)
                  navigate(`/agent/${selectedItem}/user-table/${userId}`);
                }}
              >
                View Project
              </Button>
            </div>
          );
        }
        break;
      case 'active':
        if (count === 0) {
          button = (
            <div className='d-flex'>
              <Button
                type="button"
                className="resend-button"
                disabled
              >
                Resend Invite
              </Button>
              <Button
                type="button"
                className="view-button"
                onClick={() => {
                  localStorage.setItem("user-email", email);
                  localStorage.setItem("userId", userId)
                  navigate(`/agent/${selectedItem}/saved-addresses`);
                }}
              >
                Create Project
              </Button>
            </div>
          );
        } else {
          button = (
            <div className='d-flex'>
              <Button
                type="button"
                className="resend-button"
                disabled
              >
                Resend Invite
              </Button>
              <Button
                className='view-button'
                onClick={() => {
                  localStorage.setItem("user-email", email);
                  localStorage.setItem("userId", userId)
                  navigate(`/agent/${selectedItem}/user-table/${userId}`);
                }}
              >
                View Project
              </Button>
            </div>
          );
        }
        break;
      default:
        button = null; // Set button to null if status doesn't match any case
        break;
    }
  
    return button; // Return the generated buttons
  };

  const ProjectCountFormatter = (cell, row, id) => {
    const count = row.projectCount;
    const isLargeCount = count > 10;

    return (
      <Button className='Count-Button' type="button">
        {isLargeCount ? '10+' : count}
      </Button>
    );
  };

  const columns = [];

  if (selectedItem === "repair" || selectedItem === "renovation") {
    columns.push(
      {
        formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
        dataField: 'index',
        text: 'S No.',
        headerClass: 'serial-number-header ',
        classes: 'serial-number-column',
      },
      {
        dataField: 'userInfo.first_name',
        text: 'Client Name',
        filter: textFilter({ placeholder: '' }),
      },
      {
        dataField: 'userInfo.email_address',
        text: 'Email',
        filter: textFilter({ placeholder: '' }),
      },
      {
        dataField: 'status',
        text: 'Status',
        formatter: (cell, row) => AccountStatusFormatter(cell, row, row._id),
        filter: textFilter({ placeholder: '' }),
      },
      {
        dataField: 'projectCount',
        text: 'Project',
        filter: textFilter({ placeholder: '' }),
        classes:'count',
        formatter: (cell, row) => ProjectCountFormatter(cell, row, row._id),
      },
      {
        dataField: 'action',
        text: 'Action',
        formatter: AccountActionFormatter,
        headerClass: 'status-button-header',
        classes: 'status-button-column',
      }
    );
  } else if (selectedItem === "inspection") {
    columns.push(
      {
        formatter: (cell, row, rowIndex) => pageSize * (currentPage - 1) + rowIndex + 1,
        dataField: 'index',
        text: 'S No.',
        headerClass: 'serial-number-header ',
        classes: 'serial-number-column',
      },
      {
        dataField: 'userInfo.first_name',
        text: 'Client Name',
        filter: textFilter({ placeholder: '' }),
      },
      {
        dataField: 'userInfo.email_address',
        text: 'Email',
        filter: textFilter({ placeholder: '' }),
      },
      {
        dataField: 'status',
        text: 'Status',
        formatter: AccountStatusFormatter,
        filter: textFilter({ placeholder: '' }),
      },
      {
        dataField: 'projectCount',
        text: 'Project',
        classes:'count',
        filter: textFilter({ placeholder: '' }),
        formatter: (cell, row) => ProjectCountFormatter(cell, row, row._id),
      },
      {
        dataField: 'action',
        text: 'Action',
        formatter: AccountActionFormatter,
        headerClass: 'status-button-header',
        classes: 'status-button-column',
      }
    );
  }

  const handleResendInvite = async () => {
    setButtonLoading(true)
    const userId = localStorage.getItem("user")
    try {
      const agentToken = localStorage.getItem('token');

      const response = await fetch(`${AppUrl}/agent/send-invite?userId=${userId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${agentToken}`,
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      console.log(data, "datatata");
      if (data.status === 200) {
        NotificationManager.success(data.data);
        setButtonLoading(false)
        localStorage.removeItem("user")
        setInviteModal(false)
      } else {
        NotificationManager.error("Failed");
        setButtonLoading(false)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <NotificationContainer />
      <div className="projectbtn mb-4">
        <div></div>
        <div className='create-button' onClick={handleCreate}>
          <FaPlus />
          <p>Create</p>
        </div>
      </div>

      <div className="show-entries">
        <p></p>
        {/* <div className='entries-dropdown'>
          <p className="custom-total">Show entries</p>
          <select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
            {[10, 20, 50, projects.length].map((size, index) => (
              <option key={index} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div> */}

        <div>
          <Form.Group controlId="searchInput">
            <Form.Control
              type="text"
              placeholder="Search anything here"
              value={searchText}
              onChange={handleSearchChange}
            />
          </Form.Group>
        </div>
      </div>


        {loading ? (
          <div className='loading-spinner' >
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
          </div>
        ) : (
          <div className='mb-5'>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={filteredProjects}
              columns={columns}
              filter={filterFactory({ placeholder: '' })}
              pagination={paginationFactory({
                page: currentPage,
                sizePerPage: pageSize,
                totalSize: totalCount,
                onPageChange: handlePageChange,
                sizePerPageRenderer: () => null, // Remove the size per page dropdown
                showTotal: true, // Enable showing total entries
                paginationTotalRenderer: (from, to, total) => (
                  <div className="pagination-and-entries">
                    <p className="entries">
                      Showing {from} to {to} of {total} entries
                    </p>
                  </div>
                ),
              })}
            />
            {/* <div className="pagination-div">
              <p className='entries'>
                Showing {Math.min(pageSize * (currentPage - 1) + 1, totalCount)} to {' '}
                {Math.min(pageSize * currentPage, totalCount)}
                {Math.min(pageSize * currentPage, totalCount)}  of {totalCount} entries
              </p>
              <div className="pagination">
                {currentPage !== 1 && (
                  <p
                    onClick={() => handlePageChange(currentPage - 1, pageSize)}
                    className="pagination-next-prev"
                  >
                    Previous
                  </p>
                )}

                {Array.from({ length: totalPages }, (_, index) => (
                  <Button
                    key={index}
                    onClick={() => handlePageChange(index + 1, pageSize)}
                    className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                  >
                    {index + 1}
                  </Button>
                ))}
                {currentPage !== totalPages && (
                  <p
                    onClick={() => handlePageChange(currentPage + 1, pageSize)}
                    className="pagination-next-prev"
                  >
                    Next
                  </p>
                )}
              </div>
            </div> */}
          </div>
        )}

      <Modal 
      show={inviteModal} 
      onHide={() => setInviteModal(false)} 
      className='report-modal'
      backdrop="static">
        <Modal.Body className='logout-model'>
          <p>Are you sure you want<br /> to send invite again?</p>
          <div className='d-flex justify-content-center'>
            <Button type="submit" className='btn-confirm' onClick={handleResendInvite} disabled={buttonLoading}>
              {buttonLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className='delete-spinner'
                />
              ) : (
                <h6>Confirm</h6>
              )}
            </Button>
            <Button className='btn-cancel' onClick={() => setInviteModal(false)}><h6>Cancel</h6></Button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
};

export default ProjectTable;

