import React, { useState } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap';
import { FaArrowRight } from "react-icons/fa";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';
import { useAuth } from '../../Store/auth';
import './Project.css'

const CheckEmail = () => {
    const AppUrl = appUrl();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const { loggedUser} = useAuth()

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [emailActive, setEmailActive] = useState(true)

    const backEmail = () => {
        navigate(`/agent/${selectedItem}`);
        localStorage.removeItem("user-email")
        setEmail("")
    };

    const handleEmailTyping = (e) => {
        setEmail(e.target.value)
        setEmailActive(true)
        sessionStorage.removeItem("user-email")
        setIsLoading(false)
    }

    const checkEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!email) {
            NotificationManager.error('Email address is required');
            setIsLoading(false)
            return;
        }
        if (!email.includes("@") || !email.includes(".com")) {
            NotificationManager.error('Please enter a valid email address');
            setIsLoading(false);
            return;
        }
        try {
            const agentToken = localStorage.getItem("token");
            const response = await fetch(`${AppUrl}/agent/check-email`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${agentToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email_address: email })
            });
            const data = await response.json();
            console.log(data);
            if (data.status === 200) {
                const foundUserId = data.data._id;
                localStorage.setItem("userId", foundUserId);
                localStorage.setItem("user-email", data.data?.userInfo?.email_address)
                setIsLoading(false)
                setEmailActive(true)
                navigate(`/agent/${selectedItem}/saved-addresses`)
            }
            else if (data.status === 403) {         
                setEmailActive(false);
                localStorage.setItem("userId", data.data?._id);
                localStorage.setItem("user-email", data.data?.userInfo?.email.address)
                setIsLoading(false);
            }
            else if (data.status === 400) {
                NotificationManager.error(data.message);
                setIsLoading(false);
            } else {
                NotificationManager.error('Failed to submit Email');
                setEmail('')
                setIsLoading(false);
            }
            localStorage.setItem('user-email', email)
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <NotificationContainer />
            <div>
                <div className='mb-3'>
                    <IoMdArrowBack onClick={backEmail} />
                </div>
                <Form className='check-email mb-4' id='checkemail' autoComplete='off' onSubmit={checkEmail}>
                    <Form.Group className='mb-4' controlId="formBasicPassword" >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type='email'
                            value={email}
                            onChange={handleEmailTyping}
                            autoComplete='off'
                        />
                    </Form.Group>
                    {emailActive ?
                        (<div className='check_email_btn' >
                            <Button className='emailbtn' onClick={checkEmail} disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className='address-spinner'
                                        />
                                    </>
                                ) : (
                                    <>
                                        <p>Next</p>
                                        <FaArrowRight />
                                    </>
                                )}
                            </Button>
                        </div>) :
                        (<><p className='error-email'>Email is not registered, please create an account</p>
                            <div className='create'>
                                <Button className='createbtn'
                                    onClick={() => navigate(`/agent/${selectedItem}/create-account`)} >
                                    Create Account</Button>
                            </div>
                        </>)
                    }
                </Form>
            </div>
        </>
    )
}

export default CheckEmail

