import React, { useState } from 'react';
import { Form, Button, Dropdown, Row, Col, Spinner } from 'react-bootstrap';
import { IoHome } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import { SlCalender } from "react-icons/sl";
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';
import './Project.css'

const Project = () => {

    const AppUrl = appUrl();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const selectedCategories = JSON.parse(location.state.selectedCategories);
    console.log(selectedCategories, "ola")
    const savedAddressesString = localStorage.getItem("savedAddresses");
    const savedAddresses = savedAddressesString ? JSON.parse(savedAddressesString) : [];

    const referralId = localStorage.getItem("referralId")

    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(savedAddresses.length > 0 ? savedAddresses[0].confirmAddress : '');
    const [constructionStartDate, setConstructionStartDate] = useState(null);
    const [constructionEndDate, setConstructionEndDate] = useState(null);
    const [details, setDetails] = useState({
        location: '',
        currency: '$',
        min_amount: '',
        max_amount: '',
        discription: '',
        start_date: '',
        end_date: '',
        services: [],
        project_type: '',
        project_img: null,
        referred_by: referralId || ''
    });

    console.log(details, "details")

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setDetails(prevProfile => ({
            ...prevProfile,
            project_img: file // Store the file object itself
        }));
    };

    const submitProject = async (e) => {
        // e.preventDefault()
        setIsLoading(true);
        if (parseInt(details.min_amount) === 0 || parseInt(details.max_amount) === 0) {
            NotificationManager.error('Price cannot be 0');
            setIsLoading(false);
            return;
        }
        if (parseInt(details.min_amount) > parseInt(details.max_amount)) {
            NotificationManager.error('Max price cannot be less than min price');
            setIsLoading(false);
            return;
        }
        console.log(isLoading, "check")
        if (!details.location ||
            !details.discription ||
            !details.min_amount ||
            !details.max_amount ||
            !details.start_date ||
            !details.end_date ||
            !details.project_img) {
            NotificationManager.error("Please fill all fields")
            setIsLoading(false)
            return
        }
        else {
            try {
                const formData = new FormData();
                if (details.project_img) {
                    formData.append('project_img', details.project_img);
                }
                formData.append('location', details.location);
                formData.append('currency', details.currency);
                formData.append('min_amount', details.min_amount);
                formData.append('max_amount', details.max_amount);
                formData.append('discription', details.discription);
                formData.append('start_date', details.start_date);
                formData.append('end_date', details.end_date);
                selectedCategories.forEach((service, index) => {
                    formData.append(`services[${index}]`, service);
                });
                formData.append('project_type', selectedItem);
                formData.append('referred_by', details.referred_by);
                const userId = localStorage.getItem("userId");
                const agentToken = localStorage.getItem('token');
                const response = await fetch(`${AppUrl}/agent/create-project?userId=${userId}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${agentToken}`,
                    },
                    body: formData
                });
                const data = await response.json();
                console.log(data, "datatata");
                if (data.status === 200) {
                    NotificationManager.success('project submitted successfully');
                    localStorage.removeItem("user-email", "address", "userId");
                    localStorage.removeItem("userId", "email")
                    localStorage.removeItem("savedAdddressess")
                    setConstructionStartDate(null)
                    setConstructionEndDate(null)
                    setDetails(prevDetails => ({
                        ...prevDetails,
                        amount: '',
                        location: '',
                        discription: '',
                        start_date: '',
                        end_date: '',
                        min_amount: '',
                        max_amount: '',
                        project_img: null
                    }));
                    setIsLoading(false)
                    navigate(`/agent/${selectedItem}`)
                } else {
                    NotificationManager.error(data.error);
                    setIsLoading(false)
                }
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false)
            }
        }
    };

    const handleSelect = (eventKey) => {
        const selected = savedAddresses.find(address => address.location_name === eventKey);
        setSelectedOption(selected);
        setDetails(prevDetails => ({
            ...prevDetails,
            location: selected ? selected.id : ''
        }));
    };

    const ProjectBack = () => {
        if (selectedItem === "repair" || selectedItem === "renovation") {
            navigate(`/agent/${selectedItem}/categories`)
        }
        if (selectedItem === "inspection") {
            navigate(`/agent/${selectedItem}/categories`)
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleDeleteImage = (e) => {
        e.preventDefault()
        // e.stopPropagation();
        setDetails({ ...details, project_img: null });
        const input = document.getElementById('imageInput');
        input.value = '';
    };


    return (
        <>
            <NotificationContainer />
            <div>
                <div className={`details-page ${isLoading ? 'blur-effect' : ''}`}>
                    <div className="enter-head mb-5">
                        <IoMdArrowBack onClick={ProjectBack} />
                        <h4>{selectedItem}</h4>
                        <p></p>
                    </div>

                    <h5>What is your budget</h5>
                    <div className='form-col'>
                        <Row>
                            <Col lg={6} md={6} sm={6} className='price-form mb-3'>
                                <Form.Group className='mb-4 position-relative' controlId="formBasicMinPrice" required>
                                    <Form.Label>Min Price</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name="min_amount"
                                        placeholder='Enter Min Price'
                                        value={details.min_amount}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => {
                                            if (!/^\d$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Tab'].includes(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        autoComplete='off' 
                                    />
                                    <div className='price' >$</div>
                                </Form.Group>

                            </Col>

                            <Col lg={6} md={6} sm={6} className='price-form mb-3'>
                                <Form.Group className='mb-4 position-relative' controlId="formBasicMaxPrice" required>
                                    <Form.Label>Max Price</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name="max_amount"
                                        placeholder='Enter Max Price'
                                        value={details.max_amount}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => {
                                            // Allow only numbers and certain control keys (like Backspace, Arrow keys)
                                            if (!/^\d$/.test(e.key) && !['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter', 'Tab'].includes(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        autoComplete='off' 
                                    />
                                    <div className='price' >$</div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <h5>Location</h5>
                    <Dropdown className='address-dropdown mb-4' onSelect={handleSelect}>
                        <Dropdown.Toggle className='details-address ' variant="light" id="dropdown-basic">

                            {selectedOption ?
                                (<div className='details-address'>
                                    <p><IoHome /> {selectedItem} at {selectedOption.other !== '' ? (<b>{selectedOption.other} </b>) : (<b>{selectedOption.type} </b>)}</p>
                                    <p>{selectedOption.location_name},<br />{selectedOption.complete_address}
                                    </p>
                                </div>
                                )
                                : 'Select address'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='select-address'>
                            {savedAddresses.map((address, id) => {
                                return (
                                    <Dropdown.Item key={id} eventKey={address.location_name}>
                                        <div className='details-address'>
                                            <div>
                                                <p className='address-heading mb-1'><IoHome /> {selectedItem} at {address.type === "other" && address.other !== null ? (<b>{address.other} </b>) : (<b>{address.type} </b>)}
                                                </p>
                                            </div>
                                            <p>{address.location_name},<br />{address.complete_address}
                                            </p>
                                        </div>
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Form className='details-form mb-3'>
                        <Form.Group className='mb-4' controlId="formBasicProject" required>
                            <Form.Label>Tell us about your project<span>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={1}
                                style={{ resize: "vertical" }}
                                name="discription"
                                value={details.discription}
                                onChange={handleInputChange}
                                autoComplete='off' />
                        </Form.Group>
                        <div className='form-col'>
                            <Row>
                                <Col lg={6} md={6} sm={6}>
                                    <Form.Group className='mb-4' controlId="formBasicStartDate" required>
                                        <Form.Label>Start Date</Form.Label>
                                        <br />
                                        <div className="position-relative">
                                            <DatePicker
                                                selected={constructionStartDate}
                                                onChange={(date) => {
                                                    setConstructionStartDate(date);
                                                    const formattedDate = date ? `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}` : ''; // Format the date
                                                    setDetails({ ...details, start_date: formattedDate });
                                                }}
                                                dateFormat="MM/dd/yyyy"
                                                placeholderText="Select date"
                                                showYearDropdown
                                                minDate={new Date()}
                                                maxDate={constructionEndDate}
                                                scrollableYearDropdown
                                                className="date-picker"
                                            />
                                            <SlCalender className="calendar-icon" />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col lg={6} md={6} sm={6}>
                                    <Form.Group className='mb-4' controlId="formBasicEndDate" required>
                                        <Form.Label>End Date</Form.Label>
                                        <br />
                                        <div className="position-relative">
                                            <DatePicker
                                                selected={constructionEndDate}
                                                onChange={(date) => {
                                                    setConstructionEndDate(date);
                                                    const formattedDate = date ? `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}` : ''; // Format the date
                                                    setDetails({ ...details, end_date: formattedDate });
                                                }}
                                                dateFormat="MM/dd/yyyy"
                                                placeholderText="Select date"
                                                showYearDropdown
                                                minDate={constructionStartDate || new Date()}
                                                scrollableYearDropdown
                                                className="date-picker"
                                            />
                                            <SlCalender className="calendar-icon" />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                    <h5>Upload Image</h5>
                    <div className='upload-report mb-5'>
                        <div className="image-container">
                            <div onClick={() => document.getElementById('imageInput').click()}>
                                {details.project_img ? (
                                    <>
                                        <img src={URL.createObjectURL(details.project_img)} alt="project" className='project-image' />
                                        {/* <img src='/images/pdf.png' alt='pdf' /> */}
                                    </>
                                ) : (
                                    <>
                                        <img src='/images/upload.png' className='mb-2' alt="Upload" />
                                        <h3 className='mb-2'>Drag and Drop files, or Browse</h3>
                                        <p>Upload only a image up to a maximum size of 10MB</p>
                                    </>
                                )}
                            </div>
                            {details.project_img && (
                                <button className="delete-image-btn" onClick={handleDeleteImage}>✕</button>
                            )}
                        </div>
                        <input
                            type="file"
                            id="imageInput"
                            accept="image/"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                        />
                    </div>

                    <div className='submit-details'>
                        <Button className='detailsbtn' type="submit" onClick={submitProject} disabled={isLoading}>
                            {isLoading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='address-spinner'
                                />
                            ) : (
                                <p>Submit </p>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Project
