import React, { useEffect, useState } from 'react';
import appUrl from '../appUrl';

const Support = () => {
  const appurl = appUrl();

  const [support, setSupport] = useState(null);

  const help = async () => {
    try {
      const agenttoken = localStorage.getItem("token");
      const response = await fetch(`${appurl}/agent/configuration?content_type=helpcenter`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${agenttoken}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        console.log("Support data:", responseData);

        const data = responseData.data; // Access the 'data' property

        // Remove all HTML tags from the content
        const formattedData = data.map(item => {
          const contentWithoutHTML = item.content.replace(/<[^>]+>/g, '');
          return { ...item, content: contentWithoutHTML }; // Return updated item
        });
        setSupport(formattedData); // Set the formatted data
      }
    } catch (error) {
      console.error("Error fetching About Us data:", error);
    }
  };

  useEffect(() => {
    help();
  },[]);

  return (
    <div className='terms'>
      <div>
        <h2 className='mb-4'>Help and Support</h2>
        {support ? (
          <div>
            {support.map(item => (
              <p key={item._id}>{item.content}</p>
            ))}
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Support;

