// App.js
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import AgentLogin from './Log/AgentLog/AgentLogin';
import AgentRegister from './Log/AgentLog/AgentRegister';
import './App.css';
import AgentOtp from './Log/AgentLog/AgentOtp';
import { AuthProvider } from './Store/auth';
import AgentForget from './Log/AgentLog/AgentForget';
import Homepage from './Pages/Homepage';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const { isLoggedIn } = useAuth();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  return isLoggedIn ? <Component {...rest} /> : <Navigate to="/" />;
};

function App() {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      localStorage.setItem("isLoggedIn", false);
    }
  }, [token]);

  return (
      <Routes>
        <Route
          path="/agent/*"
          element={<PrivateRoute
            component={() => (
              <>
                <Homepage />
              </>
            )}
          />}
        />

        <Route path='/agent/register'
          element={isLoggedIn ? <Navigate to="/agent" /> : <AgentRegister />}
        />
        <Route
          path='/'
          element={isLoggedIn ? <Navigate to="/agent" /> : <AgentLogin />}
        />
        <Route
          path='/agent/register/otp'
          element={isLoggedIn ? <Navigate to="/agent" /> : <AgentOtp />}
        />
        <Route
          path='/agent/forget-password'
          element={isLoggedIn ? <Navigate to="/agent" /> : <AgentForget />}
        />
        {/* <Route path="/agent/register/otp" element={<AgentOtp />} />
          <Route path="/agent/forget-password" element={<AgentForget />} /> */}
      </Routes>
  );
}

export default function WrappedApp() {
  return (
    <AuthProvider>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  );
}
