import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner, Badge, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { IoMdArrowBack } from "react-icons/io";
import appUrl from '../../appUrl';
import './Table.css'

const UserTable = () => {
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const AppUrl = appUrl()
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState('');
    const [currentTablePage, setCurrentTablePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedUserProjects, setSelectedUserProjects] = useState([]);
    const [rewardModal, setRewardModal] = useState(false)
    // const [userDetails, setUserDetails] = useState({
    //     first_name: '',
    //     last_name: "",
    //     // email: ""
    // })

    console.log(selectedUserProjects, "selectedP")
    console.log(currentTablePage, "currentTablePage")
    console.log(totalPages, "totalPages")
    console.log(totalCount, "totalCount")
    console.log(pageSize, "pageSize")

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };


    const handleProjectButtonClick = async () => {
        setLoading(true);
        console.log(id, "Apii")
        let response;
        try {
            const agentToken = localStorage.getItem('token');
            let url = '';
            if (selectedItem === "repair" || selectedItem === "renovation") {
                url = `${AppUrl}/agent/all-my-projects?project_type=${selectedItem}&homeownerId=${id}`;
            } else if (selectedItem === "inspection") {
                url = `${AppUrl}/agent/inspection/getall?project_type=${selectedItem}&homeownerId=${id}`;
            }

            response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${agentToken}`,
                    'Content-Type': 'application/json',
                }
            });

            const data = await response.json();
            console.log(data, "dataaaaa");

            if (data.status === 200 && data.data.length > 0) {
                console.log("called");
                // const projectsWithDefaults = data.data.map(project => ({
                //     ...project,
                //     services: project.services || []
                // }));
                // setSelectedUserProjects(projectsWithDefaults);
                setSelectedUserProjects(data.data)
                setPageSize(data.perPage);
                setTotalPages(data.totalPages);
                setTotalCount(data.totalCount);
            } else {
                console.error('Failed to fetch projects of the selected user');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        handleProjectButtonClick();
    }, []);

    const handlePageChange = (page, sizePerPage) => {
        setCurrentTablePage(page);
        setPageSize(sizePerPage);
    };

    // Helper function to capitalize the first letter
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const statusFormatter = (cell, row) => {
        let statusName = '';

        // Check if the status is 'inprogress' and userId.status is 'draft'
        if (row.status === 'inprogress' && row.userId.status === 'draft') {
            statusName = 'Not Active';
            return (
                <Badge bg="info">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
        // Check if the status is 'inprogress' and userId.status is 'active'
        else if (row.status === 'inprogress' && row.userId.status === 'active') {
            statusName = 'Not Hired';
            return (
                <Badge bg="warning">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
        // Check if the status is 'completed'
        else if (row.status === 'completed') {
            statusName = 'Completed';
            return (
                <Badge bg="success">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
        // Check if the status is 'cancelled'
        else if (row.status === 'cancelled') {
            statusName = 'Cancelled';
            return (
                <Badge bg="danger">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        } else {
            // Default case
            statusName = row.status;
            return (
                <Badge>
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
    };

    const inspectionStatusFormatter = (cell, row) => {
        let statusName = '';

        // Check if the status is 'inprogress' and userId.status is 'draft'
        if (row.status === 'inprogress') {
            statusName = 'Not Active';
            return (
                <Badge bg="info">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
        // Check if the status is 'inprogress' and userId.status is 'active'
        else if (row.status === 'pending') {
            statusName = 'Pending';
            return (
                <Badge bg="warning">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
        // Check if the status is 'completed'
        else if (row.status === 'completed') {
            statusName = 'Completed';
            return (
                <Badge bg="success">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
        // Check if the status is 'cancelled'
        else if (row.status === 'rejected') {
            statusName = 'Rejected';
            return (
                <Badge bg="danger">
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        } else {
            // Default case
            statusName = row.status;
            return (
                <Badge>
                    {capitalizeFirstLetter(statusName)}
                </Badge>
            );
        }
    };



    const actionFormatter = (cell, row) => {
        console.log("buttonLoading:", buttonLoading);
        let button;
        // const userId = row.userId._id;
        const userIdStatus = row.userId.status;
        const projectStatus = row.status;

        // Adjust buttons based on both userId status and project status
        if (userIdStatus === 'draft' && projectStatus === 'inprogress') {
            button =
                <Button className='Project-Button'
                    type="button"
                    disabled
                >Hire Contractor
                </Button>;
        } else if (userIdStatus === 'active' && projectStatus === 'inprogress') {
            button =
                // <Button className='Project-Button'
                //     onClick={() => handleNotification(id)}
                //     type="button"
                //     disabled={loading}
                // >Hire Contractor
                // </Button>;
                <Button
                    className='Project-Button'
                    type="submit"
                    disabled={buttonLoading}
                    onClick={() => {
                        setButtonLoading(true); // Set loading state to true before making the API call
                        handleNotification(id); // Call the handleNotification function
                    }}
                >
                    {buttonLoading ? (
                        <p>...</p>
                    ) : (
                        <p>Hire Contractor</p>
                    )}
                </Button>;
        }
        else if (projectStatus === 'completed') {
            button =
                <Button className='Project-Button'
                    type="button"
                    onClick={() => {
                        setRewardModal(true)
                    }}
                    disabled
                >Reward Collected
                </Button>;
        } else {
            button = null;
        }

        return button;
    };

    const handleNotification = async () => {
        // setButtonLoading(true)
        try {
            const agentToken = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');

            const response = await fetch(`${AppUrl}/agent/send-notification?userId=${userId}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${agentToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            console.log(data, "datatata");
            if (data.status === 200) {
                setButtonLoading(false)
                NotificationManager.success(data.data);

            } if (data.status === 404) {
                // NotificationManager.error(data.error);
            }
            else {
                // NotificationManager.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const userColumns = [];

    if (selectedItem === "repair" || selectedItem === "renovation") {
        userColumns.push(
            {
                formatter: (cell, row, rowIndex) => pageSize * (currentTablePage - 1) + rowIndex + 1,
                dataField: 'index',
                text: 'S No.',
                headerClass: 'serial-number-header ',
                classes: 'serial-number-column',
            },
            {
                dataField: 'location.location_name',
                text: 'Location',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'start_date',
                text: `${selectedItem} Start Date`,
                filter: textFilter({ placeholder: 'Search Start Date' })
            },
            {
                dataField: 'end_date',
                text: `${selectedItem} End Date`,
                filter: textFilter({ placeholder: 'Search Start Date' })
            },
            {
                dataField: 'status',
                text: 'Status',
                formatter: statusFormatter,
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: actionFormatter,
                headerClass: 'project-status-button-header',
                classes: 'project-status-button-column',
            },
        )
    } else if (selectedItem === "inspection") {
        userColumns.push(
            {
                formatter: (cell, row, rowIndex) => pageSize * (currentTablePage - 1) + rowIndex + 1,
                dataField: 'index',
                text: 'S No.',
                headerClass: 'serial-number-header ',
                classes: 'serial-number-column',
            },
            {
                dataField: 'location.location_name',
                text: 'Location',
                filter: textFilter({ placeholder: '' }),
            },
            {
                dataField: 'inspection_date',
                text: `${selectedItem} Start Date`,
                filter: textFilter({ placeholder: 'Search Start Date' })
            },
            {
                dataField: 'status',
                text: 'Status',
                formatter: statusFormatter,
            },
            {
                dataField: 'action',
                text: 'Action',
                formatter: actionFormatter,
                headerClass: 'project-status-button-header',
                classes: 'project-status-button-column',
            },
        )
    }



    return (
        <>
            <NotificationContainer />

            <div >
                <>
                    <div className=" mb-4">
                        <IoMdArrowBack onClick={() => navigate(`/agent/${selectedItem}`)} />
                    </div>

                    <div className='user-card mb-5'>
                        {selectedUserProjects.length > 0 &&
                            selectedUserProjects[0].userId &&
                            selectedUserProjects[0].userId.userBasicInfo &&
                            selectedUserProjects[0].userId.userBasicInfo.profile_image !== null && selectedUserProjects[0].userId.userBasicInfo.profile_image !== "" ? (
                            <div className='first-letter'>
                                <img src={selectedUserProjects[0].userId.userBasicInfo.profile_image} alt="" />
                            </div>
                        ) : (
                            <div className="initial-letter">
                                {selectedUserProjects.length > 0 && selectedUserProjects[0].userId && selectedUserProjects[0].userId.userInfo ? selectedUserProjects[0].userId.userInfo.first_name.slice(0, 1) : ''}
                            </div>
                        )}


                        <div className='user-details'>
                            <h3>
                                {selectedUserProjects.length > 0 ?
                                    `${selectedUserProjects[0].userId.userInfo.first_name} ${selectedUserProjects[0].userId.userInfo.last_name}`
                                    :
                                    ''
                                }
                            </h3>
                            <p>{selectedUserProjects.length > 0 ?
                                `${selectedUserProjects[0].userId.userInfo.email_address}` :
                                ''
                            }</p>
                        </div>
                    </div>
                </>

            </div >

            <div className="show-entries">
                <p></p>
                {/* <div className='entries-dropdown'>
                    <p className="custom-total">Show entries</p>
                    <select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value))}>
                        {[10, 20, 50, 100, 200, 500].map((size, index) => (
                            <option key={index} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>
                </div> */}

                <div>
                    <Form.Group controlId="searchInput">
                        <Form.Control
                            type="text"
                            placeholder="Search anything here"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Form.Group>
                </div>
            </div>

            {
                loading ? (
                    <div className="loading-spinner">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <div>
                        <BootstrapTable
                            bootstrap4
                            keyField="_id"
                            data={selectedUserProjects}
                            columns={userColumns}
                            filter={filterFactory({ placeholder: '' })}
                            pagination={paginationFactory({
                                page: currentTablePage,
                                sizePerPage: pageSize,
                                totalSize: totalCount,
                                onPageChange: handlePageChange,
                                sizePerPageRenderer: () => null, // Remove the size per page dropdown
                                showTotal: true, // Enable showing total entries
                                paginationTotalRenderer: (from, to, total) => (
                                    <div className="pagination-and-entries">
                                        <p className="entries">
                                            Showing {from} to {to} of {total} entries
                                        </p>
                                    </div>
                                ),
                            })}
                        />
                    </div>
                )
            }

        </>
    )
};

export default UserTable;


