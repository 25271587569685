import React, { useState, useEffect } from 'react'
import { Dropdown, Modal, Form, Button, Spinner } from 'react-bootstrap';
import { useAuth } from '../Store/auth';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import { FaRegBell } from "react-icons/fa6";
import appUrl from '../appUrl';

const UserName = () => {
    const [selectedItem, setSelectedItem] = useState("dashboard");
    const [logoutModal, setLogoutModal] = useState(false);
    const { loggedUser, LogoutUser } = useAuth();
    const navigate = useNavigate();
    const AppUrl = appUrl();
    const [deleteAccount, setDeleteAccount] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [count, setCount] = useState(0)

    console.log(count, "ccccccccc")

    useEffect(() => {
        if (loggedUser && loggedUser.notificationCount !== undefined) {
            setCount(loggedUser.notificationCount);
        }
    }, []);

    console.log(count, "ccccccccc");
    // setCount(loggedUser.notificationCount)

    const handleShowPassword = () => {
        setShowPassword(true);
    };

    const handleHidePassword = () => {
        setShowPassword(false);
    };

    useEffect(() => {
        const storedItem = sessionStorage.getItem('selectedItem');
        if (storedItem) {
            setSelectedItem(storedItem);
        }
    }, []); // Run once on component mount to initialize selectedItem


    const logoutUser = async (e) => {
        setButtonLoading(true)
        const deviceToken = localStorage.getItem("deviceToken")
        try {
            const agenttoken = localStorage.getItem("token");
            const response = await fetch(`${AppUrl}/agent/logout`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${agenttoken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ device_type: 'web', device_token: deviceToken || "" })
            });
            console.log('Server response:', response);
            const data = await response.json();
            console.log(data);
            if (data.status === 200) {
                navigate("/");
                localStorage.removeItem("token")
                localStorage.setItem('isLoggedIn', false);
                window.location.reload()
            }
            else {
                NotificationManager.error(data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const DeleteUser = async (e) => {
        try {
            const agenttoken = localStorage.getItem("token");
            const response = await fetch(`${AppUrl}/agent/account-delete`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${agenttoken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: password })
            });
            console.log('Server response:', response);
            const data = await response.json();
            console.log(data);
            if (response.ok && data.status === 200) {
                navigate('/');
                LogoutUser()
                NotificationManager.error(data.message)
                localStorage.clear();
            }
            else {
                NotificationManager.error(data.message);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <NotificationContainer />
            <div className='user-profile'>
                {loggedUser.profile_image ?
                    (<img src={loggedUser.profile_image} alt="" />)
                    : <img src='/images/dummy.jpg' />}
            </div>
            <Dropdown className='user'>
                <Dropdown.Toggle variant="light" id="dropdown-profile">
                    {loggedUser.first_name} {loggedUser.last_name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => navigate("/agent/edit-profile")}>Edit Profile</Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate("/agent/change-password")}>Change Password</Dropdown.Item>
                    <Dropdown.Item onClick={() => setLogoutModal(true)}>Logout</Dropdown.Item>
                    <Dropdown.Item onClick={() => setDeleteAccount(true)}>Delete Account</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <div onClick={() => {
                navigate("/agent/notification");
                setCount(0);
            }}>
                <FaRegBell id="notification-bell" className='position-relative' />
                <p id="notification-number" className='position-absolute' >{count}</p>
            </div>

            <Modal
                show={logoutModal}
                onHide={() => setLogoutModal(false)}
                className='report-modal'
                backdrop="static">
                <Modal.Body className='logout-model'>
                    <p>Are you sure you want<br /> to logout?</p>
                    <div className='d-flex justify-content-center'>
                        <Button className='btn-confirm' onClick={() => logoutUser()}>Confirm</Button>
                        <Button className='btn-cancel' onClick={() => setLogoutModal(false)}  >Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={deleteAccount}
                onHide={() => setDeleteAccount(false)}
                className='report-modal'
                backdrop="static">
                <Modal.Body className='logout-model'>
                    <p>Please enter your password to <br /> delete account?</p>
                    <Form.Group controlId="formBasicPassword" className="delete position-relative d-flex justify-content-center mb-4">
                        <Form.Control
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Password"
                            value={password}
                            style={{ width: "80%" }}
                            onChange={(e) => (setPassword(e.target.value))}
                        />
                        {showPassword ? (
                            <i className="fa-solid fa-eye delete_wrap" onClick={handleHidePassword}></i>
                        ) : (
                            <i className="fa-solid fa-eye-slash delete_wrap" onClick={handleShowPassword}></i>
                        )}

                    </Form.Group>
                    <div className='d-flex justify-content-center'>
                        {/* <Button className='btn-confirm' onClick={DeleteUser} >Confirm</Button> */}
                        <Button type="submit"
                            className='btn-confirm'
                            onClick={DeleteUser}
                            disabled={buttonLoading}
                            backdrop="static">
                            {buttonLoading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className='delete-spinner'
                                />
                            ) : (
                                <h6>Confirm</h6>
                            )}
                        </Button>
                        <Button className='btn-cancel' onClick={() => (setDeleteAccount(false), setPassword(''))}  >Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserName
