import { createContext, useContext, useEffect, useState } from "react";
import appUrl from "../appUrl";
// import {useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const appurl = appUrl()

export const AuthProvider = ({ children,navigate }) => {
  // const navigate = useNavigate()
  const [token, setToken] = useState(localStorage.getItem("token"))
  const [loggedUser, setLoggedUser] = useState({
    first_name: "",
    last_name: "",
    profile_image: null,
    location_name:'',
    phone_number:"",
    notificationCount:0
  });
  
// console.log(loggedUser,"uuuuuuuuuuuu")

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    return storedIsLoggedIn ? JSON.parse(storedIsLoggedIn) : false;
  });
  const [sidebar, setSidebar] = useState(true);
  const [savedAddresses, setSavedAddresses] = useState([])
  console.log(savedAddresses,"savedsaved")
  // const [selectedCategories, setSelectedCategories] = useState([]);
  const authorizationToken = `Bearer ${token}`

  const storeTokenInLS = (serverToken) => {
    localStorage.setItem('token', serverToken);
    setToken(serverToken);
    localStorage.setItem('isLoggedIn', true); 
    console.log("Token stored in localStorage:", serverToken);
    setIsLoggedIn(true);
    window.location.reload()
  };

  console.log("loggeddata", loggedUser)

  // const LogoutUser = () => {
  //   localStorage.setItem('isLoggedIn', false); 
  //   setIsLoggedIn(false);
  //   localStorage.removeItem("token");
  //   setToken("");
  //   // window.location.reload()
  // };

  console.log("tokencheck", token)

  //jwt authentication- to get currently logged in user data
  const userAuthentication = async () => {
    try {
      const response = await fetch(`${appurl}/agent/user-profile`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const data = await response.json();
      if (response.ok && data.status === 200) {
        console.log("user data", data.user);
        setLoggedUser(data.user);
        localStorage.setItem("agentId", data.user._id)
        localStorage.setItem("referralId", data.user.referral_id)
        setIsLoggedIn(true);
      } else if (data.status === 401) {
        setIsLoggedIn(false);
        localStorage.clear();
        setToken(""); 
        navigate("/")
        window.location.reload()
      }
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    userAuthentication();
  }, [token]);

  return (
    <>
      <AuthContext.Provider value={{ isLoggedIn, storeTokenInLS, loggedUser, setLoggedUser, authorizationToken, sidebar, setSidebar, savedAddresses, setSavedAddresses }}>
        {children}
      </AuthContext.Provider> 
    </>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return authContext;
};


