import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from '../src/Store/auth';
import reportWebVitals from './reportWebVitals';

// index.js or App.js
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//       navigator.serviceWorker.register('/firebase-messaging-sw.js')
//           .then(registration => {
//               console.log('Service Worker registered with scope:', registration.scope);
//           })
//           .catch(error => {
//               console.error('Service Worker registration failed:', error);
//           });
//   });
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <React.StrictMode>
      <App />
      <ToastContainer />
    </React.StrictMode>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

