import React, { useState } from 'react';
import { Form, Button, Dropdown, Spinner } from 'react-bootstrap';
import './Project.css';
import { IoHome } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import { SlCalender } from "react-icons/sl";
import {useNavigate,useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';

const InspectionProject = () => {
    const AppUrl = appUrl();
    const navigate= useNavigate();
    const location = useLocation(); 
    const selectedItem = location.pathname.split('/')[2];
    const savedAddressesString = localStorage.getItem("savedAddresses");
    const savedAddresses = savedAddressesString ? JSON.parse(savedAddressesString) : [];
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(savedAddresses.length > 0 ? savedAddresses[0].confirmAddress : '');
    const [inspectionDate, setInspectionDate] = useState(null);
    const [inspectionDetails, setInspectionDetails] = useState({
        location: '',
        inspection_date: "",
        referred_by: '',
        client_id: ""
    });

    const submitInspectionProject = async () => {
        setIsLoading(true);

        if (!inspectionDetails.location ||
            !inspectionDetails.inspection_date) {
            NotificationManager.error("Please fill all fields")
            setIsLoading(false)
            return
        }
        else {
            try {
                const userId = localStorage.getItem("userId");
                const referralId = localStorage.getItem("referralId")
                const agentToken = localStorage.getItem('token');
                const response = await fetch(`${AppUrl}/agent/inspection/create`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${agentToken}`,
                        'Content-Type': 'application/json' // Add Content-Type header for JSON
                    },
                    body: JSON.stringify({
                        ...inspectionDetails,
                        client_id: userId,
                        referred_by: referralId
                    })
                });
                const data = await response.json();
                console.log(data, "datatata");
                if (data.status === 200) {
                    NotificationManager.success('project submitted successfully');
                    localStorage.removeItem("user-email", "address", "userId");
                    localStorage.removeItem("userId", "email")
                    // setEmail('')
                    setInspectionDetails({
                        inspection_date: '',
                        location: ''
                    });
                    setIsLoading(false)
                    navigate(`/agent/${selectedItem}`)
                } else {
                    NotificationManager.error(data.error);
                    setIsLoading(false)
                }
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false)
            }
        }
    };
    const handleInspectionAddressSelect = (eventKey) => {
        const selected = savedAddresses.find(address => address.location_name === eventKey);
        setSelectedOption(selected);
        setInspectionDetails(prevDetails => ({
            ...prevDetails,
            location: selected ? selected.id : ''
        }));
    };
    const InspectionBack = () => {
        navigate(`/agent/${selectedItem}/saved-addresses`)
        // setInspectionDetails({
        //     inspection_date: '',
        //     location: ''
        // });
        // setInspectionDate(null)
        // setSelectedOption('')
    }

    return (
        <>
        <NotificationContainer/>
            <div className={`details-page ${isLoading ? 'blur-effect' : ''}`}>
                <div className="enter-head mb-5">
                    <IoMdArrowBack onClick={InspectionBack} />
                    <h4>Inspection</h4>
                    <p></p>
                </div>

                <Form className='details-form mb-3'>
                    <Form.Group className='mb-4' controlId="formBasicPassword" required>
                        <Form.Label>When does your inspection need to be completed by?
                        </Form.Label>
                        <br />
                        <div className="position-relative">
                            <DatePicker
                                selected={inspectionDate}
                                onChange={(date) => {
                                    setInspectionDate(date);
                                    const formattedDate = date ? `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}` : ''; // Format the date
                                    setInspectionDetails({ ...inspectionDetails, inspection_date: formattedDate });
                                }}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Select date"
                                showYearDropdown
                                minDate={new Date()}
                                scrollableYearDropdown
                                className="date-picker"
                            />
                            <SlCalender className="calendar-icon" />
                        </div>
                    </Form.Group>
                </Form>


                <h5>Address</h5>
                <Dropdown className='address-dropdown mb-4' onSelect={handleInspectionAddressSelect}>
                    <Dropdown.Toggle className='details-address ' variant="light" id="dropdown-basic">

                        {selectedOption ?
                            (<div className='details-address'>
                                <p><IoHome /> Inspection at <b>{selectedOption.type} </b></p>
                                <p>{selectedOption.location_name},<br />{selectedOption.complete_address}
                                </p>
                            </div>
                            )
                            : 'Select address'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='select-address'>
                        {savedAddresses.map((address, id) => {
                            return (
                                <Dropdown.Item key={id} eventKey={address.location_name}>
                                    <div className='details-address'>
                                        <p ><IoHome /> Inspection at <b>{address.type} </b></p>
                                        <p>{address.location_name},<br />{address.complete_address}
                                        </p>
                                    </div>
                                </Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>

                <div className='submit-details'>
                    <Button className='detailsbtn' type="submit" onClick={submitInspectionProject} disabled={isLoading}>
                        {isLoading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='address-spinner'
                            />
                        ) : (
                            <p>Submit </p>
                        )}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default InspectionProject
