// App.js
import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import AgentSidebar from '../components/Sidebar/AgentSidebar';
import UserName from '../components/UserName';
import EditProfile from './EditProfile';
import Renovation from './Renovation';
import Support from './Support';
import Terms from './Terms';
import Privacy from './Privacy';
import AboutUs from './AboutUs';
import Repair from './Repair';
import Inspection from './Inspection';
import Dashboard from './Dashboard/Dashboard';
import ChangePassword from './ChangePassword';
import Rewards from './Rewards';
import Cashback from './Cashback';
import { useAuth } from '../Store/auth';
import { RiArrowRightDoubleLine } from "react-icons/ri";
import Notifications from './Notifications';

const Homepage = () => {

    const { sidebar, setSidebar } = useAuth();


    return (
        <Row className='m-0'>
           <Col className={`side ${sidebar ? "col_1_open" : "col_1_close"}`} lg={3} md={3}>
                    {sidebar ?
                        <AgentSidebar /> :
                        <RiArrowRightDoubleLine className='arrow-icon' onClick={() => setSidebar(true)} />
                    }
                </Col>
                <Col className={`tabs ${sidebar ? "col_2_open" : "col_2_close"}`} lg={9} md={9}>
                <div className='bellicon'>
                    <UserName />
                </div>
                <div className="pages">
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="renovation/*" element={<Renovation />} />
                        <Route path="repair/*" element={<Repair />} />
                        <Route path="inspection/*" element={<Inspection />} />
                        <Route path="support" element={<Support />} />
                        <Route path="terms" element={<Terms />} />
                        <Route path="privacy" element={<Privacy />} />
                        <Route path="aboutus" element={<AboutUs />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="edit-profile" element={<EditProfile />} />
                        <Route path="rewards" element={<Rewards />} />
                        <Route path="cashback" element={<Cashback />} />
                        <Route path="notification" element={<Notifications/>} />
                    </Routes>
                </div>
            </Col>
        </Row>
    )
}

export default Homepage

