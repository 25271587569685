import React, { useState,useEffect } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import { RxDashboard } from 'react-icons/rx';
import { GiAutoRepair } from 'react-icons/gi';
import { MdConstruction } from 'react-icons/md';
import { TbHomeSearch } from 'react-icons/tb';
import { MdOutlinePrivacyTip } from 'react-icons/md';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { BsFileEarmarkText } from 'react-icons/bs';
import { RiText } from 'react-icons/ri'; // Corrected import
import { RiArrowLeftDoubleLine } from 'react-icons/ri';
import { MdRedeem } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { useAuth } from '../../Store/auth';
import { useLocation } from 'react-router-dom';

const AgentSidebar = () => {
  const { setSidebar } = useAuth();
  const [activeItem, setActiveItem] = useState('');
  const location = useLocation()

  useEffect(() => {
    const storedItem = sessionStorage.getItem('selectedItem');
    if (storedItem) {
        setActiveItem(storedItem);
    }
}, [location]); 

  const handleItemClick = (item) => {
    setActiveItem(item);
    sessionStorage.setItem('selectedItem', item);

    if (item === 'renovation' || item === 'repair' || item === 'inspection') {
      localStorage.removeItem('user-email');
      localStorage.removeItem('userId');
      localStorage.removeItem('savedAddresses');
    }
    
  };
 
  const clickSidebar = () => {
    setSidebar(false)
}

  return (
    <div className="sidebar">
      <div className='sidebar-head'>
        <div className="d-flex">
          <img src='/images/Logo.png' alt='Logo' />
          <h2>bedrock</h2>
        </div>
        <RiArrowLeftDoubleLine className="arrow-icon" onClick={clickSidebar}/>
      </div>
      <ul>
        <Link to="/agent" key="dashboard">
          <li className={activeItem === 'dashboard' ? 'active' : ''} onClick={() => handleItemClick('dashboard')}>
            <RxDashboard /> Dashboard
          </li>
        </Link>
        <Link to="/agent/renovation" key="renovation">
          <li className={activeItem === 'renovation' ? 'active' : ''} onClick={() => handleItemClick('renovation')}>
            <MdConstruction /> Renovation
          </li>
        </Link>
        <Link to="/agent/repair" key="repair">
          <li className={activeItem === 'repair' ? 'active' : ''} onClick={() => handleItemClick('repair')}>
            <GiAutoRepair /> Repair
          </li>
        </Link>
        <Link to="/agent/inspection" key="inspection">
          <li className={activeItem === 'inspection' ? 'active' : ''} onClick={() => handleItemClick('inspection')}>
            <TbHomeSearch /> Inspection
          </li>
        </Link>
        <Link to="/agent/rewards" key="rewards">
          <li className={activeItem === 'rewards' ? 'active' : ''} onClick={() => handleItemClick('rewards')}>
            <MdRedeem />
            Redeem Points
          </li>
        </Link>
        <Link to="/agent/cashback" key="cashback">
          <li className={activeItem === 'cashback' ? 'active' : ''} onClick={() => handleItemClick('cashback')}>
            <BiMoneyWithdraw />
            Cashback Available
          </li>
        </Link>
        <Link to="/agent/aboutus" key="aboutus">
          <li className={activeItem === 'aboutus' ? 'active' : ''} onClick={() => handleItemClick('aboutus')}>
            <BsFileEarmarkText />
            About Us
          </li>
        </Link>
        <Link to="/agent/privacy" key="privacy">
          <li className={activeItem === 'privacy' ? 'active' : ''} onClick={() => handleItemClick('privacy')}>
            <MdOutlinePrivacyTip />
            Privacy policy
          </li>
        </Link>
        <Link to="/agent/terms" key="terms">
          <li className={activeItem === 'terms' ? 'active' : ''} onClick={() => handleItemClick('terms')}>
            <RiText />
            Terms and Conditions
          </li>
        </Link>
        <Link to="/agent/support" key="support">
          <li className={activeItem === 'support' ? 'active' : ''} onClick={() => handleItemClick('support')}>
            <IoHelpCircleOutline />
            Support & Help Center
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default AgentSidebar;
