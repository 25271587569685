import React, { useState, useEffect } from 'react';
import { Autocomplete, GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { SlCalender } from "react-icons/sl";
import { IoMdArrowBack } from "react-icons/io";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';
import DatePicker from "react-datepicker";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { MdGpsFixed } from "react-icons/md";

const Address = ({ emailAddress }) => {
    const AppUrl = appUrl();
    const navigate = useNavigate();
    const { id } = useParams()
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    // const { savedAddresses, setSavedAddresses } = useAuth();
    // const { userId } = useParams();


    const [isLoading, setIsLoading] = useState(false);
    const [current, setCurrent] = useState({
        email_address: '',
        state: '',
        city: '',
        zip_code: '',
        location_name: '',
        longitude: '',
        latitude: '',
        radius: '200',
        type: '',
        userId: '',
        other: '',
        date_of_contruction: ''
    });
    const [constructionDate, setConstructionDate] = useState(null);
    const [selectedBtn, setSelectedBtn] = useState('home');
    const [autocomplete, setAutocomplete] = useState(null);
    const [otherInput, setOtherInput] = useState(false);
    const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
    const [address, setAddress] = useState('');
    const [mapLoaded, setMapLoaded] = useState(false);
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDtUR0XMPK_K-8zT1WCWD4Pi37jA3e1bwc'
    });

    console.log(currentLocation, "edit")
    console.log(isLoaded,"isLoaded")
    console.log(mapLoaded,"mapLoaded")
    console.log(autocomplete,"autocomplete")
    
    console.log(otherInput, "cdxcf")

    // useEffect(() => {
    //     if (id) {
    //         setCurrent({
    //             ...current,
    //             location_name: editAddress.location_name,
    //             city: editAddress.city,
    //             state: editAddress.state,
    //             zip_code: editAddress.zip_code,
    //             date_of_contruction: editAddress.date_of_contruction
    //         });
    //         setConstructionDate(new Date(editAddress.date_of_contruction));
    //         setCurrentLocation({ lat: editAddress.latitude, lng: editAddress.longitude });
    //     }
    // }, [id]);


    useEffect(() => {
        if (selectedBtn === 'other') {
            setOtherInput(true);
        }else{
            setOtherInput(false); 
        }
    }, [selectedBtn]);

    useEffect(() => {
        if (isLoaded && !loadError) {
            setMapLoaded(true);
        }
    }, [isLoaded, loadError]);

    useEffect(() => {
        if (id) {
            fetchAddressDetails(id); // Fetch address details if ID is available
        } else {
            getCurrentLocation();
        }
        setMapLoaded(true);
    }, [id]);

    // useEffect(() => {
    //     getCurrentLocation();
    //     setMapLoaded(true);
    // }, [id]);


    // useEffect(() => {
    //     if (id) {
    //         fetchAddressDetails(id);
    //     }
    // }, [id]);

    const fetchAddressDetails = async (id) => {
        const userId = localStorage.getItem("userId")
        const userEmail = localStorage.getItem("user-email")
        try {
            const agentToken = localStorage.getItem("token")
            const response = await fetch(`${AppUrl}/agent/list-address-by-id/?addressId=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${agentToken}`,
                }
            });
            const data = await response.json();
            if (data.status === 200) {
                const addressData = data.data;
                const [month, year] = addressData.date_of_contruction.split('/'); // Split the date string
                const constructedDate = new Date(parseInt(year), parseInt(month) - 1);
                setCurrent({
                    ...current,
                    location_name: addressData.location.location_name,
                    city: addressData.complete_address.city,
                    state: addressData.complete_address.state,
                    zip_code: addressData.complete_address.zip_code,
                    date_of_contruction: constructedDate,
                    // date_of_contruction:"",
                    userId: userId,
                    email_address: userEmail,
                    // type: addressData.type,
                    longitude: addressData.location.coordinates[0],
                    latitude: addressData.location.coordinates[1],
                    other: addressData.other
                });
                setConstructionDate(constructedDate);
                setSelectedBtn(addressData.type);
                setCurrentLocation({ lat: addressData.location.coordinates[1], lng: addressData.location.coordinates[0], });
            } else {
                NotificationManager.error(data.error);
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrent(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (name === "location_name" && value.trim() === "") {
            // If location is cleared, reset other fields
            setCurrent(prevState => ({
                ...prevState,
                city: '',
                state: '',
                zip_code: '',
                date_of_contruction: ''
            }));
        }
    }
    console.log(current, "current")

    const handlePlaceSelect = () => {
        if (!autocomplete) {
            console.error('Autocomplete is not yet initialized');
            return;
        }
        const addressObject = autocomplete.getPlace();
        const addressComponents = addressObject.address_components;

        if (!addressObject.geometry || !addressObject.geometry.location) {
            setCurrent(prevState => ({
                ...prevState,
                location_name: '',
                city: '',
                state: '',
                zip_code: '',
            }));
            NotificationManager.error('Please select an address from the dropdown');
            return;
        }

        let locationName = addressComponents.slice(0, 4).map(component => component.long_name).join(', ');

        let address = {
            ...current, // Spread the previous state to preserve other fields
            location_name: locationName,
        };

        addressComponents.forEach(addressComponent => {
            const addressType = addressComponent.types[0];
            switch (addressType) {
                case 'postal_code':
                    address.zip_code = addressComponent.long_name;
                    break;
                case 'administrative_area_level_3':
                    address.city = addressComponent.short_name;
                    break;
                case 'administrative_area_level_1':
                    address.state = addressComponent.long_name;
                    break;
                default:
                    break;
            }
        });

        address.latitude = addressObject.geometry.location.lat();
        address.longitude = addressObject.geometry.location.lng();

        setCurrent(address);
        setCurrentLocation(addressObject.geometry.location);
    };

    const createNewAddress = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (!current.state || !current.city || !current.zip_code || !current.location_name || !current.date_of_contruction) {
            NotificationManager.error("Please fill all fields");
            setIsLoading(false);
        }
        else if(current.type === "other" && current.other === ''){
            NotificationManager.error("Please fill all fields");
            setIsLoading(false);
        } 
        else {
            try {
                const agentToken = localStorage.getItem("token")
                const user = localStorage.getItem("userId")
                const response = await fetch(`${AppUrl}/agent/add-address`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${agentToken}`,
                    },
                    body: JSON.stringify({
                        ...current,
                        type: selectedBtn,
                        email_address: emailAddress,
                        userId: user,
                    })
                });

                const data = await response.json();
                console.log(data)
                if (data.status === 200) {
                    setIsLoading(false);
                    navigate(`/agent/${selectedItem}/saved-addresses`)
                } else if (data.status === 400) {
                    NotificationManager.error(data.error);
                    setIsLoading(false);
                }
                else {
                    NotificationManager.error(data.error);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false);
            }
        }
    };

    const updateAddress = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!current.state || !current.city || !current.zip_code || !current.location_name || !current.date_of_contruction) {
            NotificationManager.error("Please fill all fields");
            setIsLoading(false);
        } 
        else if(current.type === "other" && current.other === ''){
            NotificationManager.error("Please fill all fields");
            setIsLoading(false);
        }     
        else {
            try {
                const agentToken = localStorage.getItem("token");
                const response = await fetch(`${AppUrl}/agent/update-address`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${agentToken}`,
                    },
                    body: JSON.stringify({
                        ...current,
                        type: selectedBtn,
                        id: id
                    })
                });

                const data = await response.json();
                console.log(data);
                if (data.status === 200) {
                    setIsLoading(false);
                    navigate(`/agent/${selectedItem}/saved-addresses`)
                } else if (data.status === 400) {
                    NotificationManager.error(data.error);
                    setIsLoading(false);
                } else {
                    NotificationManager.error(data.error);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false);
            }
        }
    };

    // const handleLocationIconClick = () => {
    //     if (!navigator.geolocation) {
    //         console.error('Geolocation is not supported by this browser.');
    //         return;
    //     }

    //     navigator.permissions.query({ name: 'geolocation' }).then((result) => {
    //         if (result.state === 'prompt' || result.state === 'denied') {
    //             navigator.geolocation.getCurrentPosition(
    //                 position => {
    //                     const { latitude, longitude } = position.coords;
    //                     setCurrentLocation({
    //                         lat: latitude,
    //                         lng: longitude
    //                     });
    //                     getAddressFromCoordinates(latitude, longitude);
    //                 },
    //                 error => {
    //                     console.error('Error getting current location:', error);
    //                     // If permission is denied or geolocation is not supported, set default position to New York
    //                     setCurrentLocation({
    //                         lat: 40.730610,
    //                         lng: -73.935242
    //                     });
    //                     // Optionally, you can also update the address
    //                     setAddress('New York, NY, USA');
    //                 }
    //             );
    //         } else if (result.state === 'denied') {
    //             console.error('Location access is denied by the user.');
    //         }
    //     });
    // };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const handleMarkerDrag = event => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setCurrentLocation({ lat, lng });
        getAddressFromCoordinates(lat, lng);
    };

    const getAddressFromCoordinates = (latitude, longitude) => {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat: latitude, lng: longitude };
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const addressObject = results[0];
                    const addressComponents = addressObject.address_components;

                    let locationName = addressComponents.slice(0, 4).map(component => component.long_name).join(', ');

                    let address = {
                        ...current, // Spread the previous state to preserve other fields
                        location_name: locationName,
                    };

                    addressComponents.forEach(addressComponent => {
                        const addressType = addressComponent.types[0];
                        switch (addressType) {
                            case 'postal_code':
                                address.zip_code = addressComponent.long_name;
                                break;
                            case 'administrative_area_level_3':
                                address.city = addressComponent.short_name;
                                break;
                            case 'administrative_area_level_1':
                                address.state = addressComponent.long_name;
                                break;
                            default:
                                break;
                        }
                    });

                    address.latitude = latitude;
                    address.longitude = longitude;
                    setCurrent(address);
                    setAddress(results[0].formatted_address);
                } else {
                    console.error('No results found');
                }
            } else {
                console.error('Geocode was not successful for the following reason: ' + status);
            }
        });
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({
                        lat: latitude,
                        lng: longitude
                    });
                    getAddressFromCoordinates(latitude, longitude);
                },
                error => {
                    console.error('Error getting current location:', error);
                    // If permission is denied or geolocation is not supported, set default position to New York
                    setCurrentLocation({
                        lat: 40.730610,
                        lng: -73.935242
                    });
                    // Optionally, you can also update the address
                    setAddress('New York, NY, USA');
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    // useEffect(() => {
    //     if (editAddress) {
    //         setCurrent({
    //             ...current,
    //             location_name: editAddress.location.location_name,
    //             city: editAddress.complete_address.city,
    //             state: editAddress.complete_address.state,
    //             zip_code: editAddress.complete_address.zip_code,
    //             date_of_contruction: editAddress.date_of_contruction
    //         });
    //     }
    // }, [editAddress]);

    return (
        <>
            <NotificationContainer />
            <div className={`enter-address ${isLoading ? 'blur-effect' : ''}`}>
                <div className='enter-head mb-3'>
                    <IoMdArrowBack onClick={() => navigate(`/agent/${selectedItem}/saved-addresses`)} />
                    <h5>Enter Address</h5>
                    <p></p>
                </div>
                <div className='address-type mb-3'>
                    <h6 className='mb-2'>Save Address as</h6>
                    <div className='address-type-btn'>
                        <Button
                            onClick={() => setSelectedBtn('home')}
                            className={selectedBtn === 'home' ? 'selected' : ''}
                        >
                            Home
                        </Button>
                        <Button
                            onClick={() => setSelectedBtn('work')}
                            className={selectedBtn === 'work' ? 'selected' : ''}
                        >
                            Work
                        </Button>
                        {otherInput ? (
                            <>
                                <Button
                                    onClick={() => setSelectedBtn('other')}
                                    className={selectedBtn === 'other' ? 'selected' : ''}
                                >
                                    Other
                                </Button>
                                <div className='other-details'>
                                    <Form.Group controlId="formOtherDetails">
                                        <Form.Control
                                            as="textarea"
                                            rows={1}
                                            value={current.other}
                                            name="other"
                                            placeholder='Save as'
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </div>
                            </>
                        ) : (
                            <Button
                                onClick={() => {
                                    setSelectedBtn('other');
                                    setOtherInput(true);
                                }}
                                className={selectedBtn === 'other' ? 'selected' : ''}
                            >
                                Other
                            </Button>
                        )}
                    </div>
                </div>

                <Form className='address-details mb-3' onSubmit={createNewAddress}>
                    <Form.Group className='location position-relative  mb-3' controlId="formBasicPassword" required>
                        <Form.Label>Location</Form.Label>
                        <Autocomplete
                            onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                            onPlaceChanged={handlePlaceSelect}
                        >
                            <Form.Control
                                name="location_name"
                                value={current.location_name}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                type="text"
                                placeholder="Enter location name"
                            />
                        </Autocomplete>
                        <MdGpsFixed />
                    </Form.Group>

                    {/* <p>Current Location: {address}</p> */}
                    {mapLoaded && isLoaded && !loadError && (
                        <div className="mb-3">
                            <MapWithMarker className="mb-5" currentLocation={currentLocation} onMarkerDrag={handleMarkerDrag} />
                        </div>
                    )}
                    {loadError && <div>Error loading map. Please try again later.</div>
                    }

                    <Form.Group className='mb-3' controlId="formBasicPasswordCity" required>
                        <Form.Label>Street/Unit/City</Form.Label>
                        <Form.Control type='text' name="city" value={current.city} onChange={handleInputChange} />
                    </Form.Group>

                    <div className='form-col'>
                        <Row>
                            <Col lg={6} md={6} sm={6}>
                                <Form.Group className='mb-3' controlId="formBasicPasswordState" required>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control type='text' name="state" value={current.state} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} sm={6}>
                                <Form.Group className='mb-3 ' controlId="formBasicPasswordZip" required>
                                    <Form.Label>Zip code</Form.Label>
                                    <Form.Control type='text' name="zip_code" value={current.zip_code} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <Form.Group className='mb-5' controlId="formBasicPasswordDate" required>
                        <Form.Label>Date of construction</Form.Label>
                        <br />
                        <div className="position-relative">
                            <DatePicker
                                selected={constructionDate}
                                placeholderText="Select date"
                                onChange={(date) => {
                                    setConstructionDate(date);
                                    const formattedDate = date ? `${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}` : '';
                                    setCurrent(prevState => ({ ...prevState, date_of_contruction: formattedDate }));
                                }}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                showYearDropdown
                                scrollableYearDropdown
                                className="date-picker"
                                maxDate={new Date()}
                            />
                            <SlCalender className="calendar-icon" />
                        </div>
                    </Form.Group>

                    {/* <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1" required>
                        <Form.Label>Confirm Address</Form.Label>
                        <Form.Control as="textarea" rows={3} name="confirm_address" value={current.confirm_address} onChange={handleInputChange} />
                    </Form.Group> */}

                </Form>
            </div>

            <div className='addbutton'>
                {id ? (
                    <Button className='addressbtn' type="submit" disabled={isLoading} onClick={updateAddress}>
                        {isLoading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='address-spinner'
                            />
                        ) : (
                            <p>Update address</p>
                        )}
                    </Button>
                ) : (
                    <Button className='addressbtn' type="submit" disabled={isLoading} onClick={createNewAddress}>
                        {isLoading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='address-spinner'
                            />
                        ) : (
                            <p>Save address</p>
                        )}
                    </Button>
                )}
            </div>

        </>
    )
};

const MapWithMarker = ({ currentLocation, onMarkerDrag }) => {
    const mapStyles = {
        height: '300px',
        width: '100%'
    };

    // const onLoad = React.useCallback(function onLoadCallback(map) {
    //     const bounds = new window.google.maps.LatLngBounds({ lat: 0, lng: 0 });
    //     map.fitBounds(bounds);
    // }, []);

    const onLoad = React.useCallback(function onLoadCallback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(currentLocation);
        // map.fitBounds(bounds);
    }, []);


    const onUnmount = React.useCallback(function onUnmountCallback(map) { }, []);

    return (
        <GoogleMap
            mapContainerStyle={mapStyles}
            center={currentLocation}
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker
                position={currentLocation}
                draggable={true}
                onDragEnd={onMarkerDrag}
            />
        </GoogleMap>
    );
};

export default Address;