import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../Register.css';
import '../Log.css'
import '../../components/Modals/Modal.css';
import appUrl from '../../../src/appUrl';
import { requestForToken } from '../../firebase';
import AgentChecklist from './AgentChecklist';
import { IoMdArrowBack } from "react-icons/io";

const AgentRegister = () => {
    const navigate = useNavigate();
    const AppUrl = appUrl();
    requestForToken()

    const [agentData, setAgentData] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        password: '',
        confirm_password: '',
        checkbox: false,
        state_licence: '',
        licence_number: '',
        user_type: "agent",
        device_type: "web",
        // device_token: "netscapelabs"
    });
    const [agent, setAgent] = useState(true)
    const [agentInfo, setAgentInfo] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(true);
    };

    const handleHidePassword = () => {
        setShowPassword(false);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(true);
    };

    const handleHideConfirmPassword = () => {
        setShowConfirmPassword(false);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setAgentData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleLogin = () => {
        navigate('/');
    };

    const handleNext = () => {
        if (agentData.password !== agentData.confirm_password) {
            NotificationManager.error("Passwords do not match");
            setIsLoading(false);
        } else if (
            agentData.first_name &&
            agentData.last_name &&
            agentData.email_address &&
            agentData.phone_number &&
            agentData.checkbox &&
            //password validation
            agentData.password.length >= 8 && // Check minimum length
            /[!@#$%^&*(),.?":{}|<>]/.test(agentData.password) && // Check for special characters
            /\d/.test(agentData.password) && // Check for numbers
            /[A-Z]/.test(agentData.password) && // Check for capital letters
            /[a-z]/.test(agentData.password) // Check for lowercase letters
        ) {
            setAgentInfo(true);
            setAgent(false);
        } else {
            NotificationManager.error("Please fill all fields and ensure the password meets the required criteria.");
            setIsLoading(false);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const deviceTokenPromise = requestForToken();
            const deviceToken = await deviceTokenPromise;
            console.log(deviceToken, "check")
            const response = await fetch(`${AppUrl}/agent/signup`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ...agentData, device_token: deviceToken || "" }),
                });
            const data = await response.json();
            console.log("res from server", data)
            if (data.status === 200) {
                localStorage.setItem("token", data.token)
                navigate('/agent/register/otp', { state: { email: agentData.email_address } })
                NotificationManager.success(data.message);
                setIsLoading(false);
            } else if (data.status === 400) {
                NotificationManager.error("email already exist");
                setIsLoading(false);
            } else if (data.status === 403) {
                NotificationManager.error(data.message);
                setIsLoading(false);
            } else {
                NotificationManager.error('Failed to submit form. Please fill all fields.');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
        }
    };

    return (
        <>
            {agent && <Row className='m-0'>
                <Col className='register' lg={6} md={6} sm={12}>
                    <div className="register_form_wrap">
                        <h2 className='mb-2'>Create an account</h2>
                        <p className='mb-3'>All fields are required</p>
                        <Form autoComplete='off'>
                            <Form.Group controlId="validationFormikFirstName" className="position-relative mb-3">
                                <i className="fa-solid fa-user login_icon_wrap"></i>
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    name="first_name"
                                    value={agentData.first_name}
                                    onChange={handleInputChange}
                                    autoComplete='off' />
                            </Form.Group>

                            <Form.Group controlId="validationFormikLastName" className="position-relative mb-3">
                                <i className="fa-solid fa-user login_icon_wrap"></i>
                                <Form.Control
                                    type="text"
                                    placeholder="Last Name"
                                    name="last_name"
                                    value={agentData.last_name}
                                    onChange={handleInputChange}
                                    autoComplete='off' />
                            </Form.Group>

                            <Form.Group controlId="validationFormikEmail" className="position-relative mb-3" required>
                                <i className="fa-solid fa-envelope login_icon_wrap"></i>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    name="email_address"
                                    value={agentData.email_address}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                    required />
                            </Form.Group>

                            <Form.Group controlId="validationFormikPhoneNumber" className="position-relative mb-3">
                                <i className="fa-solid fa-phone login_icon_wrap"></i>
                                <Form.Control
                                    type="number"
                                    placeholder="Phone Number"
                                    name="phone_number"
                                    value={agentData.phone_number}
                                    onChange={handleInputChange}
                                    autoComplete='off' />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword"
                                className="position-relative mb-3">
                                <i className="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Password"
                                    value={agentData.password}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />
                                {
                                    agentData.password && agentData.password.length > 0 ?
                                        <AgentChecklist
                                            password={agentData.password || ""}
                                            rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                        /> : ``
                                }
                                {showPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHidePassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap" onClick={handleShowPassword}></i>
                                )}

                            </Form.Group>
                            <Form.Group controlId="formBasicPassword"
                                className="position-relative mb-3">
                                <i className="fa-solid fa-lock login_icon_wrap"></i>
                                <Form.Control
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    name="confirm_password"
                                    placeholder="Confirm Password"
                                    value={agentData.confirm_password}
                                    onChange={handleInputChange}
                                    autoComplete='off'
                                />
                                {showConfirmPassword ? (
                                    <i className="fa-solid fa-eye password_wrap" onClick={handleHideConfirmPassword}></i>
                                ) : (
                                    <i className="fa-solid fa-eye-slash password_wrap" onClick={handleShowConfirmPassword}></i>
                                )}

                            </Form.Group>

                            <label className='registeraccount mb-3'>
                                <input type="checkbox" id="myCheckbox" checked={agentData.checkbox} onChange={handleInputChange} name="checkbox" /> I have read and agree to the <span>Privacy Policy</span> and <br />
                                <span>Terms and Conditions</span>
                            </label>
                            <Button onClick={handleNext} className='registerbtn mb-3'>Next</Button>{' '}
                        </Form>
                        <p className='already'>Already have an account  ? <span onClick={handleLogin}>Sign in</span></p>
                    </div>
                </Col>
                <Col className='logo-column' lg={6} md={6} sm={12}>
                    <div className='column-center'>
                        <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>
                        <div><h6>Agent</h6></div>
                    </div>
                </Col>
            </Row>}

            {agentInfo && (
                <>

                    <Row className='m-0'>


                        <Col className='register' lg={6} md={6} sm={12}>
                            <div className="register_form_wrap pt-5">
                                <div className="mb-4">
                                    <IoMdArrowBack onClick={() => {
                                        setAgentInfo(false);
                                        setAgent(true);
                                    }} />
                                </div>
                                <h2 className='mb-4'>My Information</h2>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className='mb-3' controlId="formBasicPassword">
                                        <Form.Label>States Licensed</Form.Label>
                                        <Form.Control
                                            style={{ border: "0.5px solid #B1B1B1", padding: "14px" }}
                                            type="text"
                                            placeholder=""
                                            value={agentData.state_licence}
                                            name="state_licence"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className='mb-5' controlId="formBasicPassword">
                                        <Form.Label>License Number</Form.Label>
                                        <Form.Control
                                            style={{ border: "0.5px solid #B1B1B1", padding: "14px" }}
                                            type="number"
                                            placeholder=""
                                            value={agentData.licence_number}
                                            name="licence_number"
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Button className='registerbtn mb-3' type="submit" disabled={isLoading}>Sign Up</Button>
                                </Form>
                            </div>
                        </Col>
                        <Col className='logo-column' lg={6} md={6} sm={12}>
                            <div className='column-center'>
                                <div><p> <img src='/images/logo2.png' alt='logo' />bedrock</p></div>
                                <div><h5>Agent</h5></div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            <NotificationContainer />
        </>
    );
};

export default AgentRegister;
