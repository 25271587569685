import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import appUrl from '../../appUrl';
import { Button, Dropdown } from 'react-bootstrap';
import { FaArrowRight } from "react-icons/fa";
import { MdOutlineMyLocation, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import './Project.css'

const SavedAddresses = () => {

    const AppUrl = appUrl();
    const navigate = useNavigate();
    const location = useLocation();
    const selectedItem = location.pathname.split('/')[2];
    const [savedAddresses, setSavedAddresses] = useState([])
    // const [addressToDelete, setAddressToDelete] = useState(null);
    // const [deleteModal, setDeleteModal] = useState(false);
    

    // const userEmail = localStorage.getItem("user-email");


    const listAddresses = async () => {
        try {
            const userId = localStorage.getItem("userId")
            const agentToken = localStorage.getItem('token');

            const response = await fetch(`${AppUrl}/agent/list-address?userId=${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${agentToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            console.log(data, "datatata");
            if (data.status === 200) {
                const addresses = data.data.map(address => ({
                    state: address.complete_address.state,
                    zip_code: address.complete_address.zip_code,
                    city: address.complete_address.city,
                    date_of_contruction: address.date_of_contruction,
                    location_name: address.location.location_name,
                    status: address.status,
                    type: address.type,
                    other: address.other,
                    id: address._id
                }));
                setSavedAddresses(addresses);
            } else {
                NotificationManager.error('Failed to retrieve addresses');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // const DeleteAddress = async (id) => {
    //     setIsLoading(true)
    //     try {
    //         const userId = localStorage.getItem("userId")
    //         const agentToken = localStorage.getItem('token');
    //         const response = await fetch(`${AppUrl}/agent/delete-address?id=${id}&userId=${userId}`, {
    //             method: 'DELETE',
    //             headers: {
    //                 Authorization: `Bearer ${agentToken}`,
    //                 'Content-Type': 'application/json',
    //             }
    //         });
    //         const data = await response.json();
    //         console.log(data, "datatata");
    //         if (data.status === 200) {
    //             const updatedAddresses = savedAddresses.filter(address => address.id !== id);
    //             setSavedAddresses(updatedAddresses);
    //             NotificationManager.success('Address deleted successfully');
    //              setIsLoading(false)
    //               setDeleteModal(false)            
    //         } else {
    //             NotificationManager.error(data.error);
    //             setIsLoading(false)
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    console.log(selectedItem,
        'item')

    useEffect(() => {
        listAddresses();
    }, []);


    const AddressNext = () => {

        if (selectedItem === "repair" || selectedItem === "renovation") {
            navigate(`/agent/${selectedItem}/categories`)
        }
        if (selectedItem === "inspection") {
            navigate(`/agent/${selectedItem}/project`)
        }
    }


    return (
        <>
            <NotificationContainer />
            <div className='mb-3'>
                <IoMdArrowBack onClick={() => {
                    navigate(`/agent/${selectedItem}`)
                }} />
            </div>
            <div className='saved-Address-mb-5'>
                <div className='mb-5'>
                    <h5>Addresses</h5>
                    <div className='add-address mb-5'>
                        <div className='address-head' onClick={() => navigate(`/agent/${selectedItem}/address`)}>
                            <p>+ Add Address</p> <MdOutlineKeyboardArrowRight />
                        </div>
                        <hr />
                        <div onClick={() => navigate(`/agent/${selectedItem}/address`)}> <p><MdOutlineMyLocation /> Use your current location </p></div>
                    </div>

                    {savedAddresses.length > 0 ?
                        (<div style={{ display: 'flex', alignItems: 'center' }}>
                            <hr style={{ flex: 1, margin: '0 20px' }} />
                            <p className='saved-hr'>Saved Addresses</p>
                            <hr style={{ flex: 1, margin: '0 20px' }} />
                        </div>)
                        : null
                    }

                    {savedAddresses && savedAddresses.map((address, index) => (
                        <div key={index}>

                            <div className='display-address'>
                                <div className='edit-address d-f'>
                                    <div><p className='address-heading mb-1'><IoHome /> {selectedItem} at {address.type === "other" && address.other !== null ? (<b>{address.other} </b>) : (<b>{address.type} </b>)}
                                    </p>
                                    </div>
                                    <div className='edit-address-icons'>
                                        <Dropdown className='saved-addresses-dropdown'>
                                            <Dropdown.Toggle variant="light" id="dropdown-basic">

                                                <i class="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    const id = address.id;
                                                    navigate(`/agent/${selectedItem}/edit-address/${id}`)
                                                    // const newUrl = `/agent/renovation?id=${userId}`;
                                                    // window.history.pushState({}, '', newUrl);                                         
                                                }}>Edit</Dropdown.Item>
                                                {/* <Dropdown.Item onClick={() => {
                                                    setDeleteModal(true);
                                                    setAddressToDelete(address);
                                                }}>Delete</Dropdown.Item> */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <p className='address-details'>{address.location_name}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    {savedAddresses && savedAddresses.length > 0 ?
                        (<div className='check_email_btn'>
                            <Button className='emailbtn' onClick={() => {
                                AddressNext();
                                localStorage.setItem("savedAddresses", JSON.stringify(savedAddresses));
                            }}>
                                <p>Next</p>
                                <FaArrowRight />
                            </Button>
                        </div>)
                        : null
                    }</div>
            </div>
            {/* <Modal show={deleteModal} onHide={() => setDeleteModal(false)} className='report-modal' >
                <Modal.Body className='logout-model'>
                    <p>Are you sure you want<br /> to delete this address?</p>
                    <div className='d-flex justify-content-center'>
                        <Button type="submit" className='btn-confirm' onClick={() => DeleteAddress(addressToDelete.id)} disabled={isLoading}
                        > {isLoading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className='delete-spinner'
                            />
                        ) : (<h6>Delete</h6>)
                            }
                        </Button>
                        <Button className='btn-cancel' onClick={() => setDeleteModal(false)}  ><h6>Cancel</h6></Button>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    )
}

export default SavedAddresses
